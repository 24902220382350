import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { addCustomersForm } from "../../redux/actions";
import { addCustomersData } from "../../redux/actions/Api";
import { MSG } from "../../utils/messages";
import { EMAIL_REGEX, NAME_REGEX, NUM_REGEX } from "../../utils/Regex";
import { useForm } from "react-hook-form";
import DOMPurify from 'dompurify';

const AddCustomer = () => {
  const dispatch = useDispatch();
  const {getAllCustomer } = useSelector(
    (data) => data?.collections
  );
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({ mode: "onTouched" });

  const [show, setShow] = useState(true);
  const [status, setStatus] = useState(false);
  const [marketPreferences, setMarketPreferences] = useState(false);

  const handleClose = () => {
    setShow(false);
    dispatch(addCustomersForm(false));
  };

  const toggleStatus = () => {
    setStatus(!status);
  };

  const toggleMarketPreferences = () => {
    setMarketPreferences(!marketPreferences);
  };


  const onSubmit = (data) => {
    const isEmailExists = getAllCustomer?.data?.some(
      (customer) => customer.email.toLowerCase() === data.email.toLowerCase()
    );
    if (isEmailExists) {
      setError("email", { type: "manual", message: "Email already exists!" });
      return; 
    }
    const payload = {
      firstName: DOMPurify.sanitize(data.firstName),
      middleName: DOMPurify.sanitize(data.middleName) || "",
      lastName: DOMPurify.sanitize(data.lastName),
      mobile: DOMPurify.sanitize(data.mobileNumber),
      dob: DOMPurify.sanitize(data.dob),
      address: DOMPurify.sanitize(data.address),
      email: DOMPurify.sanitize(data.email),
      country: DOMPurify.sanitize(data.country) || "Australia",
      city: DOMPurify.sanitize(data.city),
      state: DOMPurify.sanitize(data.state),
      zipcode: DOMPurify.sanitize(data.zip),
      status: status ? "Active" : "Inactive",
      marketPref: marketPreferences ? "Active" : "Inactive",
    };
    dispatch(addCustomersData(payload));
    dispatch(addCustomersForm(false));
    reset();
  };

  const handleEmail = (e) => {
    let emailAddress = e.target.value.trim();
    const isEmailExists = getAllCustomer?.data?.some(
      (customer) => customer.email.toLowerCase() === emailAddress.toLowerCase()
    );
    if (isEmailExists) {
      setError("email", { type: "manual", message: "Email already exists!" });
    } else {
      clearErrors("email");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-4">
            <label htmlFor="firstName" className="form-label">
              First Name<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              placeholder="First Name"
              className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
              {...register("firstName", {
                required: MSG.FNAMEREQ,
                pattern: {
                  value: NAME_REGEX,
                  message: "First name " + MSG.ALPHAREQ,
                },
                maxLength: {
                  value: 50,
                  message: MSG.MAX50CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="middleName" className="form-label">
              Middle Name
            </label>
            <input
              type="text"
              id="middleName"
              placeholder="Middle Name"
              className="form-control"
              {...register("middleName")}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="lastName" className="form-label">
              Last Name<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="lastName"
              placeholder="Last Name"
              className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
              {...register("lastName", {
                required: MSG.LNAMEREQ,
                pattern: {
                  value: NAME_REGEX,
                  message: "Last name " + MSG.ALPHAREQ,
                },
                maxLength: {
                  value: 50,
                  message: MSG.MAX50CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.lastName?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="mobileNumber" className="form-label">
              Phone<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="mobileNumber"
              placeholder="Phone"
              className={`form-control ${
                errors.mobileNumber ? "is-invalid" : ""
              }`}
              {...register("mobileNumber", {
                required: MSG.MOBNUMREQ,
                pattern: {
                  value: /^[0-9]+$/,
                  message: MSG.NUMREQ,
                },
              })}
            />
            <div className="invalid-feedback">
              {errors.mobileNumber?.message}
            </div>
          </div>

          <div className="col-md-4">
            <label htmlFor="dob" className="form-label">
              DOB<span className="mandatoryField">*</span>
            </label>
            <input
              type="date"
              id="dob"
              className={`form-control ${errors.dob ? "is-invalid" : ""}`}
              {...register("dob", {
                required: MSG.DOBREQ,
              })}
            />
            <div className="invalid-feedback">{errors.dob?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="email" className="form-label">
              Email<span className="mandatoryField">*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              {...register("email", {
                onChange: (e) => handleEmail(e),
                required: {
                  value: true,
                  message: MSG.EMAILSREQ,
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: MSG.INVEMAILREQ,
                },
                maxLength: {
                  value: 100,
                  message: MSG.MAX100CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>

          <div className="col-md-12">
            <label htmlFor="address" className="form-label">
              Street<span className="mandatoryField">*</span>
            </label>
            <textarea
              id="address"
              placeholder="Street"
              rows="1"
              className={`form-control ${errors.address ? "is-invalid" : ""}`}
              {...register("address", {
                required: MSG.STREETREQ,
              })}
            ></textarea>
            <div className="invalid-feedback">{errors.address?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="city" className="form-label">
              Suburb / City<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="city"
              placeholder="City"
              className={`form-control ${errors.city ? "is-invalid" : ""}`}
              {...register("city", {
                required: MSG.CITYREQ,
              })}
            />
            <div className="invalid-feedback">{errors.city?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="state" className="form-label">
              State<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="state"
              placeholder="State"
              className={`form-control ${errors.state ? "is-invalid" : ""}`}
              {...register("state", {
                required: MSG.STATEREQ,
              })}
            />
            <div className="invalid-feedback">{errors.state?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="zip" className="form-label">
              Pin Code<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="zip"
              placeholder="Pin Code"
              className={`form-control ${errors.zip ? "is-invalid" : ""}`}
              {...register("zip", {
                required: {
                  value: true,
                  message: MSG.ZCODE,
                },
                maxLength: 6,
                pattern: {
                  value: NUM_REGEX,
                  message: MSG.INZCODE,
                },
              })}
            />
            <div className="invalid-feedback">{errors.zip?.message}</div>
          </div>

          <div className="col-md-6">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <select
              id="country"
              className="form-select"
              {...register("country")}
            >
              <option value="Australia">Australia</option>
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
            </select>
          </div>

          {/* <div className="col-md-6">
            <label className="form-label">Status</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="statusToggle"
                checked={status}
                onChange={toggleStatus}
              />
              <label className="form-check-label" htmlFor="statusToggle">
                {status ? "Active" : "Inactive"}
              </label>
            </div>
          </div> */}

          <div className="col-md-6">
            <label className="form-label">Market Preferences</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="marketPreferencesToggle"
                checked={marketPreferences}
                onChange={toggleMarketPreferences}
              />
              <label
                className="form-check-label"
                htmlFor="marketPreferencesToggle"
              >
                {marketPreferences ? "Active" : "Inactive"}
              </label>
            </div>
          </div>
          <hr />
          <div className="col-md-12 text-end">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCustomer;
