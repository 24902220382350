import { responseFailure } from ".";
import { API_PATHS } from "../../utils";
import { getAxios, getUnauthedAxios } from "../../utils/helper";
import {
  GET_CUSTOMERS_DATA,
  GET_EMPLOYEE_DATA,
  RESPONSE,
  SUBSCRIBDATA,
  LOGIN_SUCCESS,
  GET_COMPANY_DATA,
  GET_COMPANY_LOAN,
  UPDATETHEME,
  LOAN_PRODUCTS,
  LOANSDATA,
} from "./Type";

export const getAllCustomerData = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.get(`${API_PATHS.GET_ALL_CUSTOMERS}`);
    dispatch({
      type: GET_CUSTOMERS_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const addCustomersData = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.ADD_CUSTOMERS}`,
        formData
      );
      dispatch({
        type: RESPONSE,
        payload: data,
      });
      dispatch(getAllCustomerData());
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const addSecondryCustData = (loanid, formData) => async (dispatch) => {
  try {
    if (loanid && formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.ADD_SECONDRY_CUSTOMERS}/${loanid}/add-secondary-applicant`,
        formData
      );

      dispatch({
        type: RESPONSE,
        payload: data,
      });
    }

    dispatch(getAllCustomerData());
  } catch (error) {
    console.error("Error adding secondary customer data:", error);
    dispatch(responseFailure(error));
  }
};



export const getAllEmployeeData = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.get(`${API_PATHS.GET_ALL_EMPLOYEE}`);
    dispatch({
      type: GET_EMPLOYEE_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const getAllCompanyData = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.get(`${API_PATHS.GET_ALL_COMPANY}`);
    dispatch({
      type: GET_COMPANY_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const addCompanyData = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.GET_ALL_COMPANY}`,
        formData
      );
      dispatch({
        type: RESPONSE,
        payload: data,
      });
      dispatch(getAllCompanyData());
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const addEmployeeData = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.ADD_EMPLOYEE}`,
        formData
      );
      dispatch({
        type: RESPONSE,
        payload: data,
      });
      dispatch(getAllEmployeeData());
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const getSubscriberFormData = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getUnauthedAxios();
      const { data } = await instance.post(
        `${API_PATHS.SUBSCRIBER_API}`,
        formData
      );
      dispatch({ type: SUBSCRIBDATA, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const userSignin = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getUnauthedAxios();
      const { data } = await instance.post(API_PATHS.FREE_SIGNIN_API, formData);
      localStorage.setItem("access_token", data?.token);
      dispatch({ type: LOGIN_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const getAllLoan = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.get(`${API_PATHS.GET_ALL_LOAN}`);
    dispatch({
      type: GET_COMPANY_LOAN,
      payload: data,
    });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const getLoanProduct = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.get(API_PATHS.GET_LOAN_PRODUCTS);
    dispatch({
      type: LOAN_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const postLoanDetails = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        API_PATHS.POST_LOAN_DETAILS,
        formData
      );
      dispatch({
        type: LOANSDATA,
        payload: data,
      });
      dispatch(getAllLoan())
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};

export const updateTheme = (themeData) => async (dispatch) => {
  try {
    if (themeData) {
      console.log("sss");
      const instance = getUnauthedAxios();
      const { data } = await instance.post(
        `${(API_PATHS.UPDATE_THEME, themeData)}`
      );
      dispatch({
        type: UPDATETHEME,
        payload: data,
      });
    }
  } catch (error) {
    dispatch(responseFailure(error));
  }
};


export const addSecondryExistingData = (loanid, userId) => async (dispatch) => {
  try {
    if (loanid && userId) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.ADD_SECONDRY_CUSTOMERS}/${loanid}/add-existing-secondary-applicant`,
        {"userId":userId}
      );

      dispatch({
        type: RESPONSE,
        payload: data,
      });
    }

    dispatch(getAllLoan());
  } catch (error) {
    dispatch(responseFailure(error));
  }
};