export const OPENTHEMESETTING = "OPENTHEMESETTING";
export const ADDCUSTOMERSFORM = "ADDCUSTOMERSFORM";
export const ADDCOMPANYFORM = "ADDCOMPANYFORM";
export const ADDEMPLOYEEFORM ="ADDEMPLOYEEFORM";
export const LOGIN_SUCCESS ="LOGIN_SUCCESS";
export const LOGOUT ="LOGOUT";
export const CUSTOMERDETAILSPAGE ="CUSTOMERDETAILSPAGE";
export const GET_CUSTOMERS_DATA = "GET_CUSTOMERS_DATA";
export const RESPONSE_FAILURE = "RESPONSE_FAILURE";
export const RESPONSE = "RESPONSE";
export const ADDCONTRACTDATA ="ADDCONTRACTDATA";
export const GET_EMPLOYEE_DATA = "GET_EMPLOYEE_DATA";
export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const SUBSCRIBDATA ="SUBSCRIBDATA";
export const SELECTEDDATA = "SELECTEDDATA";
export const BIRTHDATA ="BIRTHDATA";
export const ROLEDATA = "ROLEDATA";
export const GET_COMPANY_LOAN = "GET_COMPANY_LOAN";
export const LOAN_PRODUCTS = "LOAN_PRODUCTS";
export const UPDATETHEME ="UPDATETHEME";
export const ADDLOANFORM ="ADDLOANFORM";
export const LOANSDATA = "LOANSDATA";
export const ADDSECONDRYDATA ="ADDSECONDRYDATA";
export const LOANDETAILSPAGE = "LOANDETAILSPAGE";
export const EMPDETAILSPAGE = "EMPDETAILSPAGE";

