import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem('access_token');
  if (!isLoggedIn) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default PrivateRoute;
