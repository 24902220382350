import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "./redux/actions/Api";

const ThemeCustomizer = () => {
  const dispatch = useDispatch();
  const { openThemeSettingModal } = useSelector((state) => state.collections);

  const [theme, setTheme] = useState("blue-theme");

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", theme);
  }, [theme]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const themes = { theme };
    dispatch(updateTheme(theme))
    console.log("Selected Theme:", theme);
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        data-bs-scroll="true"
        tabIndex="-1"
        id="staticBackdrop"
      >
        <div className="offcanvas-header border-bottom h-70">
          <div>
            <h5 className="mb-0">Theme Customizer</h5>
            <p className="mb-0">Customize your theme</p>
          </div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <p>Theme variation</p>
            <div className="row g-3">
              {[
                { id: "blue-theme", icon: "contactless", label: "Blue" },
                { id: "light", icon: "light_mode", label: "Light" },
                { id: "dark", icon: "dark_mode", label: "Dark" },
              ].map((themeOption) => (
                <div key={themeOption.id} className="col-12 col-xl-6">
                  <input
                    type="radio"
                    className="btn-check"
                    name="theme-options"
                    id={themeOption.id}
                    onChange={() => setTheme(themeOption.id)}
                    checked={theme === themeOption.id}
                  />
                  <label
                    className="btn btn-outline-secondary d-flex flex-column gap-1 align-items-center justify-content-center p-4"
                    htmlFor={themeOption.id}
                  >
                    <span className="material-icons-outlined">
                      {themeOption.icon}
                    </span>
                    <span>{themeOption.label}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-md-12 text-end p-2">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-secondary ms-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default ThemeCustomizer;
