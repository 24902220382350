import React, { useEffect, useState } from "react";
import avatars from "../../assets/images/avatars/01.png";
import Home from "../../Home";
import {
  addEmployeeForm,
  openEmpDetails,
  resetResponse,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Success from "../../Success";
import { getAllEmployeeData } from "../../redux/actions/Api";
import { getName } from "../../utils/helper";
import EmployeeDetails from "./EmployeeDetails";

const EmployeeList = () => {
  const dispatch = useDispatch();
  const { getAllEmployee, response, openEmpDetailsData } = useSelector(
    (data) => data?.collections
  );

  const [employeeData, setEmployeeData] = useState([]);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [empData, setEmpData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(addEmployeeForm(false));
    dispatch(getAllEmployeeData());
  }, []);

  const handleOpenEmployee = () => {
    dispatch(addEmployeeForm(true));
  };

  useEffect(() => {
    if (response) {
      toast.info(<Success response={response} />, {});
      dispatch(resetResponse());
    }
  }, [response]);

  useEffect(() => {
    if (getAllEmployee && getAllEmployee?.data?.length > 0) {
      setEmployeeData(getAllEmployee);
      setFilteredEmployee(getAllEmployee?.data);
    }
  }, [getAllEmployee]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredEmployee(employeeData?.data || []);
    } else {
      const filtered = employeeData?.data?.filter((employ) => {
        const nameMatch = getName(employ?.user)
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const emailMatch = employ?.user?.email
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        const mobileMatch = employ?.user?.mobile
          ?.toString()
          .includes(searchQuery);
        const address = employ?.user?.address?.toString().includes(searchQuery);
        const city = employ?.user?.city?.toString().includes(searchQuery);
        const state = employ?.user?.state?.toString().includes(searchQuery);
        const zipCode = employ?.user?.zipcode?.toString().includes(searchQuery);
        const jobtitle = employ?.jobTitle?.toString().includes(searchQuery);
        return (
          nameMatch ||
          emailMatch ||
          mobileMatch ||
          address ||
          city ||
          state ||
          zipCode ||
          jobtitle
        );
      });
      setFilteredEmployee(filtered);
    }
    setCurrentPage(1);
  }, [searchQuery, employeeData]);

  const handleEmployeeDetails = (emp) => {
    dispatch(openEmpDetails(emp));
  };

  const totalItems = filteredEmployee?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems =
    filteredEmployee &&
    filteredEmployee?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <Home>
      {openEmpDetailsData ? (
        <EmployeeDetails />
      ) : (
        <main class="main-wrapper">
          <div class="main-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div class="breadcrumb-title pe-3">Employees</div>
              <div class="ps-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0 p-0">
                    <li class="breadcrumb-item">
                      <a href="javascript:;">
                        <i class="bx bx-home-alt"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Employees List
                    </li>
                  </ol>
                </nav>
              </div>
              <div class="ms-auto">
                <div class="btn-group">
                  <button type="button" class="btn btn-primary">
                    Settings
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                    {" "}
                    <a class="dropdown-item" href="javascript:;">
                      Action
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Another action
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Something else here
                    </a>
                    <div class="dropdown-divider"></div>{" "}
                    <a class="dropdown-item" href="javascript:;">
                      Separated link
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="product-count d-flex align-items-center gap-3 gap-lg-4 mb-4 fw-bold flex-wrap font-text1">
              <a href="javascript:;">
                <span class="me-1">All</span>
                <span class="text-secondary">(85472)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">New</span>
                <span class="text-secondary">(145)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Checkouts</span>
                <span class="text-secondary">(89)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Locals</span>
                <span class="text-secondary">(5872)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Subscribers</span>
                <span class="text-secondary">(163)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Top Reviews</span>
                <span class="text-secondary">(8)</span>
              </a>
            </div>

            <div class="row g-3">
              <div class="col-auto">
                <div class="col-auto">
                  <div class="position-relative">
                    <input
                      className="form-control px-5"
                      type="search"
                      placeholder="Search Customers"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <span class="material-icons-outlined position-absolute ms-3 translate-middle-y start-0 top-50 fs-5">
                      search
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-auto flex-grow-1 overflow-auto">
                <div class="btn-group position-static">
                  <div class="btn-group position-static">
                    <button
                      type="button"
                      class="btn btn-filter dropdown-toggle px-4"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More Filters
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#">
                          All Employees
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Active Employees
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item" href="#">
                          Inactive Employees
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex align-items-center gap-2 justify-content-lg-end">
                  <button class="btn btn-filter px-4">
                    <i class="bi bi-box-arrow-right me-2"></i>Export
                  </button>
                  <button
                    class="btn btn-primary px-4"
                    onClick={handleOpenEmployee}
                  >
                    <i class="bi bi-plus-lg me-2"></i>Add Employee
                  </button>
                </div>
              </div>
            </div>
            {!getAllEmployee && currentItems?.length === 0 ? (
              <div className="text-center py-4">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
            ) : (
              <div class="card mt-4">
                <div class="card-body">
                  <div class="customer-table">
                    <div class="table-responsive white-space-nowrap">
                      <table class="table align-middle">
                        <thead class="table-light">
                          <tr>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Mobile Number</th>
                            {/* <th>Date of Birth</th> */}
                            <th>Address</th>
                            <th>Job Title</th>
                            <th>Hire Date</th>
                            <th>Salary</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentItems?.map((emp, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  class="d-flex align-items-center gap-3"
                                  href="#"
                                >
                                  {/* <div class="customer-pic">
                                  <img
                                    src={avatars}
                                    class="rounded-circle"
                                    width="40"
                                    height="40"
                                    alt=""
                                  />
                                </div> */}
                                  <p
                                    className="mb-0 customer-name fw-bold"
                                    onClick={() => handleEmployeeDetails(emp)}
                                  >
                                    {getName(emp?.user)}
                                  </p>
                                </a>
                              </td>
                              <td>
                                <a
                                  href={`mailto:${emp?.user?.email}`}
                                  className="font-text1"
                                >
                                  {emp?.user?.email}
                                </a>
                              </td>
                              <td>{emp?.user?.mobile}</td>
                              {/* <td>{emp?.user?.dob}</td> */}
                              <td>
                                {" "}
                                {emp?.user?.address} {emp?.user?.city}{" "}
                                {emp?.user?.state} {emp?.user?.country}{" "}
                                {emp?.user?.zipcode}
                              </td>
                              <td>{emp.jobTitle}</td>
                              <td>{emp.hireDate}</td>
                              <td>{emp.salary}</td>
                              <td>{emp?.user?.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3 p-3">
                  <div>
                    <label>
                      Items per page:{" "}
                      <select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </label>
                  </div>
                  <div>
                    <button
                      className={`btn btn-secondary me-2 nav-button ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className={`btn btn-secondary ms-2 nav-button next-button ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      )}
    </Home>
  );
};

export default EmployeeList;
