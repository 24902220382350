import React, { useEffect, useState } from "react";
import avatars from "../../assets/images/avatars/01.png";
import { useDispatch, useSelector } from "react-redux";
import { addCustomersForm, resetResponse, selectedData } from "../../redux/actions";
import Home from "../../Home";
import { formatDate, getName } from "../../utils/helper";
import { getAllCustomerData } from "../../redux/actions/Api";

const BirthdayUsers = () => {
  const dispatch = useDispatch();
  const { getAllCustomer } = useSelector((data) => data?.collections);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filterType, setFilterType] = useState("currentBirthday");
  const [formDate, setFormDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    if (!getAllCustomer) {
      dispatch(getAllCustomerData());
    }
  }, []);

  useEffect(() => {
    if (getAllCustomer && getAllCustomer.data) {
      setCustomers(getAllCustomer);
      setFilteredCustomers(getAllCustomer.data);
    }
  }, [getAllCustomer]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredCustomers(customers?.data || []);
    } else {
      const filtered = customers?.data?.filter((customer) => {
        const nameMatch = getName(customer)
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const emailMatch = customer?.cust_email
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        const mobileMatch = customer?.cust_mobileNumber
          ?.toString()
          .includes(searchQuery);
        const address = customer?.cust_address
          ?.toString()
          .includes(searchQuery);
        const city = customer?.city?.toString().includes(searchQuery);
        const state = customer?.state?.toString().includes(searchQuery);
        const zipCode = customer?.zipcode?.toString().includes(searchQuery);

        return (
          nameMatch ||
          emailMatch ||
          mobileMatch ||
          address ||
          city ||
          state ||
          zipCode
        );
      });
      setFilteredCustomers(filtered);
    }
    setCurrentPage(1);
  }, [searchQuery, customers]);

  const totalItems = filteredCustomers?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = filteredCustomers?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedCustomers(filteredCustomers?.map((cust) => cust.cust_id));
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleSelectCustomer = (customerId) => {
    let updatedSelection;
    if (selectedCustomers.includes(customerId)) {
      updatedSelection = selectedCustomers.filter((id) => id !== customerId);
    } else {
      updatedSelection = [...selectedCustomers, customerId];
    }
    setSelectedCustomers(updatedSelection);
    setSelectAll(updatedSelection.length === filteredCustomers.length);
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
    if (type === "currentBirthday") {
      setFormDate("");
      setToDate("");
    }
  };

  const handleFormDateChange = (e) => {
    const newFormDate = e.target.value;
    setFormDate(newFormDate);
    if (toDate && newFormDate > toDate) {
      setToDate(newFormDate);
    }
  };

  const handleToDateChange = (e) => {
    const newToDate = e.target.value;
    if (newToDate >= formDate) {
      setToDate(newToDate);
    }
  };

  useEffect(() => {
    if (!customers?.data) return;
    let filtered = customers?.data;
    const today = new Date().toISOString().slice(5, 10);
    if (filterType === "currentBirthday") {
      filtered = customers?.data?.filter(
        (cust) => cust?.cust_dob && cust?.cust_dob?.slice(5, 10) === today
      );
    } else if (filterType === "dateRange" && formDate && toDate) {
      const startDate = new Date(formDate);
      const endDate = new Date(toDate);

      filtered = customers?.data?.filter((cust) => {
        const custDOB = new Date(cust?.cust_dob);
        return custDOB >= startDate && custDOB <= endDate;
      });
    }

    setFilteredCustomers(filtered);
    setSelectAll(false);
    setSelectedCustomers([]);
    setCurrentPage(1);
  }, [filterType, customers, formDate, toDate]);

  useEffect(() => {
    if (selectedCustomers) {
      dispatch(selectedData(selectedCustomers));
    }
  }, [selectedCustomers]);

  return (
    <Home>
      <div className="card mt-4">
        <div className="card-body">
          <div class="row g-3">
            {/* <div class="col-auto">
              <div class="position-relative">
                <input
                  className="form-control px-5"
                  type="search"
                  placeholder="Search Customers"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <span class="material-icons-outlined position-absolute ms-3 translate-middle-y start-0 top-50 fs-5">
                  search
                </span>
              </div>
            </div> */}
            <div class="col-auto">
              <div class="d-flex align-items-center gap-2 justify-content-lg-end">
                <button
                  type="button"
                  className="btn btn-filter"
                  onClick={() => handleFilterChange("currentBirthday")}
                >
                  CurrentBirthday
                </button>
                <span> Form </span>
                <input
                  type="date"
                  id="formDate"
                  className="form-control"
                  value={formDate}
                  onChange={handleFormDateChange}
                />
                <span>To</span>
                <input
                  type="date"
                  id="formDate"
                  className="form-control"
                  value={toDate}
                  onChange={handleToDateChange}
                  min={formDate}
                />

                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!formDate && !toDate}
                  onClick={() => handleFilterChange("dateRange")}
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <div className="customer-table mt-3">
            <div className="table-responsive white-space-nowrap">
              <table className="table align-middle">
                <thead className="table-light">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Date of Birth</th>
                    <th>Address</th>
                    {/* <th>Status</th>
                    <th>Market Preferences</th> */}
                  </tr>
                </thead>
                {currentItems?.length > 0 || customers === undefined ? (
                  <tbody>
                    {currentItems &&
                      currentItems?.length > 0 &&
                      currentItems?.map((customer, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedCustomers.includes(
                                customer.cust_id
                              )}
                              onChange={() =>
                                handleSelectCustomer(customer.cust_id)
                              }
                            />
                          </td>
                          <td>
                            <a
                              className="d-flex align-items-center gap-3"
                              href="#"
                            >
                              <p className="mb-0 customer-name fw-bold">
                                {getName(customer)}
                              </p>
                            </a>
                          </td>
                          <td>
                            <a
                              href={`mailto:${customer.cust_email}`}
                              className="font-text1"
                            >
                              {customer.cust_email}
                            </a>
                          </td>
                          <td>{customer.cust_mobileNumber}</td>
                          <td>{formatDate(customer.cust_dob)}</td>
                          <td>
                            {customer.cust_address} {customer.city}{" "}
                            {customer.state} {customer.country}{" "}
                            {customer.zipcode}
                          </td>
                          {/* <td>{customer.cust_status}</td>
                        <td>{customer.cust_market_pref}</td> */}
                        </tr>
                      ))}
                  </tbody>
                ) : filteredCustomers?.length === 0 ? (
                  <div className="align-items-center p-5">
                    <p>No Records Founds</p>
                  </div>
                ) : (
                  <div className="align-items-center p-5">
                    <p>Loading...</p>
                  </div>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3 p-3">
          <div>
            <label>
              Items per page:{" "}
              <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
            </label>
          </div>
          <div>
            <button
              className={`btn btn-secondary me-2 nav-button ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className={`btn btn-secondary ms-2 nav-button next-button ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default BirthdayUsers;
