export const MSG = {
    FNAMEREQ: "First name is required",
    LNAMEREQ: "Last name is required",
    NAMEREQ: "Name is required",
    FULLNAMEREQ: "Full name is required",
    STREETREQ: "Street is required",
    NAMEPROVIDEREQ: "Please provide a name",
    EMAILPROVIDEREQ: "Please provide an email",
    TITLEREQ: "Please enter a title for the reward",
    TARGETREQ: "Please enter target points to get reward",
    TARGETDESC: "Please enter a description for the reward",
    TARGETDT: "Please enter expiry date to get reward",
    DESCRIPTION: "Description is required",
    DOBREQ: "Date of birth is required",
    HIREREQ: "Hire Date is required",
    JOBREQ: "Job Title is required!",
    LOANREQ: "Loan Category is required!",
    LOANTYPEREQ: "Loan Type is required!",
    AGGREREQ: "Aggregator is required!",
    BANKREQ: " Bank Name is required!",
    LOANAMREQ: " Loan Amount is required!",
    INTRREQ: "Interest Rate is required!",
    LOANTEREQ: "Loan Term is required!",
    REPAYREQ: "Repayment Term is required!",
    PURPREQ: "Purpose is required!",
    JOINREQ: "Joining Date is required!",
    SETTREQ: "Settlement Date is required!",
    EMPLREQ: " Employment Type is required!",
    COMPREQ: " Company Name is required!",
    DESREQ: " Designation is required!",
    MINCREQ: "Monthly Income is required!",    
    COMREQ: "Customer is required!",
    DATEREQ: "Date is required",
    ALPHAREQ: "can only contain letters",
    EMAILREQ: "Email/Username is required",
    EMAILSREQ: "Email is required",
    INVEMAILREQ: "Please enter a valid email address",
    INVURLREQ: "Please enter a valid url",
    UNAMEREQ: "Username is required",
    PASSREQ: "Please enter your password",
    CPASSREQ: "Confirm your new password",
    NEWPASSREQ: "Please enter your new password",
    OLDPASSREQ: "Please enter your current password",
    ZCODE: "Pincode is required",
    INZCODE: "Invalid Pincode",
    ADDR1REQ: "Address 1 is required",
    CITYREQ: "City is required",
    MAX100CHREQ: "Maximum 100 charaters are allowed",
    URLREQ: "URL is required",
    VLINKREQ: "Please enter atleast one video link",
    ACTNAMEREQ: "Activity name is required",
    MAX255CHREQ: "Maximum 255 charaters are allowed",
    VALIDINFOREQ: "Insert valid inforamtion",
    MAX15CHREQ: "Maximum 15 characters",
    MOBNUMREQ: "Mobile number is required",
    ABNNUMREQ: "ABN number is required",
    ACNNUMREQ: "ACN number is required",
    PHONENUMREQ: "Phone number is required",
    SALARYREQ: "Salary is required",
    INVMOBNUMREQ: "Invalid Mobile number",
    SCORREQ: "Score is required",
    INTRESTREQ: "Interest is required",
    COURSEREQ: "Course is required",
    VALIDNAMREQ: "Please Enter Valid Name",
    LINKREQ: "Link is required",
    NUMREQ: "Only numbers allowed",
    SKILL:
      "You Need to remove first from selected elective skill to add new skill",
    GRADEREQ: "Grade is required",
    STATEREQ: "State is required",
    TITLEEXREQ: "Title Name is required",
    COUNTRYREQ: "Country is required",
    RESOURCETAB: "Resource Tab",
    COURSETAB: "Course Counter",
    HELPTAB: "Need Help Information ?",
    COMPLETEDTAB: "Lesson Complete Information ?",
    SCHOOLNMREQ: "School name is required",
    SUBREQ: "Subject is required",
    KEYAREAEXP:
      " Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it",
    STRNGPWD: "Password does not meet the provided criteria",
    ONENUM: "At least one number",
    EIGHTCHAR: "Password must contain at least 8 characters",
    UPPRLWRCHAR: "At least one uppercase and one lowercase character",
    SPCLCHAR: "At least one special character like “& . $ @”",
    PWDNOTMTCH: "Passwords do not match",
    MAX50CHREQ: "Maximum 50 characters",
    MAX15CHREQ: "Maximum 15 characters",
    MAX100CHREQ: "Maximum 100 characters",
    MAX255CHREQ: "Maximum 255 characters",
    NORESOURCEFOUND: "No record found",
    AGE18_: "You need to be 18 years and above to sign up",
    AGE02_: "Your child needs to be 2 years and above to sign up",
  
    AGE18: "You need to be 18 years and above",
    AGE02: "Your child needs to be 2 years and above",
  
    AGEMAX20: "Child age should not be more then 20 years",
    CARDREQ: "Card number is required",
    EXPDATEREQ: "Expiry date is required",
    CVVREQ: "CVV is required",
    VALIDCARD: "This does not appear to be a valid credit card",
    VALIDEXP: "Please enter a valid expiration date",
    VALIDCVV: "This does not appear to be a valid CVV",
    REQEMAIL: "Please enter your email",
    REQNAME: "Please enter your name",
    MATCH_PASS: "Current password and new password cannot be same",
  
    TERMSNCONDITION:
      "I agree to the terms and conditions as set out by the user agreement and privacy policy.",
    NEWSLTRCONDITION: `By signing up you are agreeing to receive bloomster.com newsletter via email. You can unsubscribe later.`,
  
    RELATEDTOMSG: "Please select what your feedback is about",
    FEEDBACKDESCMSG: "Please enter your feedback",
    GENREQ: "Gender is required",
    PILOTLAUNCH:
      "Hi there! I have just begun my journey of helping children become more confident, empowered, and self-aware. If interested for your child, please fill the form below.",
    INVITEMSG: "You cannot invite yourself",
    SERVEYSCOREMSG: "Please provide a score",
    SERVEYRATINGMSG: "Please select a rating",
    FEEDBACKDROPMSG: "Please select an option",
    INVALIDDATE: "Invalid Date",
    FUTUREDATE: "Date of birth can not be greater than today",
    MAXAGE: "Your child needs to be below 18 years",
    _MAXAGE: "Your child needs to be below 18 years to sign up",
    TARGETPT: "Target points need to be less than or equal to maximum points",
    TARGETED: "Expiry date cannot be in the past",
    TARGETMIN: "Target points need to be greater than 0",
    VRFYAGE: "Please confirm you are 18 years or older",
    SKILLREQ: "Please select a skill",
    COURSE_DESC: "Please enter the course description",
    HEADLINEREQ: "Please provide a headline",
    CORPOINT: "Target points need to be greater than gained points",
    EMAIL_EXIST: "Email already exists",
    VALID_PWD: "Please enter valid password",
    VALID_ABN: "Please enter valid number",
  };