import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo1 from "../../assets/images/gallery/18.png";
import logo2 from "../../assets/images/avatars/02.png";
import { openCustomerDetails } from "../../redux/actions";
import { getName } from "../../utils/helper";

const CustomerDetails = () => {
  const { openCustomersPage } = useSelector((data) => data?.collections);
  const dispatch = useDispatch();

  const backCustomer = () => {
    dispatch(openCustomerDetails(""));
  };
  return (
    <main class="main-wrapper">
      <div class="main-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div class="breadcrumb-title pe-3">Customers</div>
          <div class="ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item">
                  <a href="javascript:;">
                    <i class="bx bx-home-alt"></i>
                  </a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Customer Details
                </li>
              </ol>
            </nav>
          </div>
          <div class="ms-auto">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary"
                onClick={backCustomer}
              >
                <i class="bi bi-arrow-left"></i> Back
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-4 d-flex">
            <div class="card w-100">
              <div class="card-body">
                <div class="position-relative">
                  <img src={logo1} class="img-fluid rounded" alt="" />
                  <div class="position-absolute top-100 start-50 translate-middle">
                    <img
                      src={logo2}
                      width="100"
                      height="100"
                      class="rounded-circle raised p-1 bg-white"
                      alt=""
                    />
                  </div>
                </div>
                <div class="text-center mt-5 pt-4">
                  <h4 class="mb-1">{getName(openCustomersPage)}</h4>
                  <p class="mb-0">Marketing Excutive</p>
                </div>
              </div>

              <ul className="list-group list-group-flush">
                <li className="list-group-item border-top">
                  <b>Email</b>
                  <br />
                  {openCustomersPage?.email}
                </li>
                <li className="list-group-item">
                  <b>Phone</b>
                  <br />
                  {openCustomersPage?.mobile}
                </li>
                <li className="list-group-item">
                  <b>Address</b>
                  <br />
                  {openCustomersPage?.street}
                  {openCustomersPage?.city} {openCustomersPage?.state}{" "}
                  {openCustomersPage?.country} {openCustomersPage?.zipcode}
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 col-lg-8 d-flex">
            <div class="card w-100">
              <div class="card-body">
                <h5 class="mb-3">Send Notes to Customer</h5>
                <textarea
                  class="form-control"
                  placeholder="write somthing"
                  rows="6"
                  cols="6"
                ></textarea>
                <button class="btn btn-filter w-100 mt-3">Add Meesage</button>
              </div>
              <div class="customer-notes mb-3">
                <div class="bg-light mx-3 my-0 rounded-3 p-3">
                  <div class="notes-item">
                    <p class="mb-2">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. of letters, as opposed to using 'Content
                      here, content here.
                    </p>
                    <p class="mb-0 text-end fst-italic text-secondary">
                      10 Apr, 2022
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="mb-3">Customer Details</h5>
            <div class="product-table">
              <div class="table-responsive white-space-nowrap">
                <table class="table align-middle">
                  <thead class="table-light">
                    <tr>
                      <th>Customer Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Date of Birth</th>
                      <th>Address</th>
                      <th>Status</th>
                      <th>Market Prefrence</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{getName(openCustomersPage)}</td>
                      <td>{openCustomersPage?.email}</td>
                      <td>{openCustomersPage?.mobile}</td>
                      <td>{openCustomersPage?.dob}</td>
                      <td>
                        {" "}
                        {openCustomersPage?.street}
                        {openCustomersPage?.city} {openCustomersPage?.state}{" "}
                        {openCustomersPage?.country}{" "}
                        {openCustomersPage?.zipcode}
                      </td>
                      <td>{openCustomersPage?.status}</td>
                      <td>{openCustomersPage?.customer?.marketPref}</td>
                      {/* <td>
                        <div>
                          <span>
                            <i class="bi bi-pencil-fill text-primary me-2"></i>
                          </span>
                          <span>
                            <i class="bi bi-trash-fill text-danger me-2"></i>
                          </span>
                        </div>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CustomerDetails;
