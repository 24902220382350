import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import pdfToText from "react-pdftotext";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { addContractOpen } from "../../redux/actions";

const AddContract = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [show, setShow] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [textData, setTextData] = useState("");
  const [details, setDetails] = useState({
    applyOnlineID: "",
    applicationID: "",
    applicants: [],
    loanDetails: "",
  });
  const [selectedRole, setSelectedRole] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [roles] = useState([
    "Sales Staff",
    "Loan Writer",
    "Manager",
    "Administrator",
  ]);

  const handleClose = () => {
    setShow(false);
    dispatch(addContractOpen(false));
  };

  const handleFile = (selectedFile) => {
    setFile(selectedFile);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) handleFile(selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) handleFile(selectedFile);
  };

  const onSubmit = (data) => {
    if (file) {
      pdfToText(file)
        .then((text) => {
          setTextData(text);
          extractDetails(text);
        })
        .catch((error) =>
          console.error("Failed to extract text from PDF", error)
        );
    } else {
      console.log("No file provided");
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setTextData("");
    setDetails({
      applyOnlineID: "",
      applicationID: "",
      applicants: [],
      loanDetails: "",
    });
  };

  const extractDetails = (text) => {
    const applyOnlineIDMatch = text.match(/ApplyOnline ID:\s+([\w\-]+)/);
    const applicationIDMatch = text.match(/Application ID:\s+([\w\-]+)/);
    const primaryApplicantMatch = text.match(
      /Mr\s+([\w\s]+)\s+\(Primary Applicant\)/
    );
    const coApplicantMatch = text.match(/Mrs\s+([\w\s]+)\s+\(Co-Applicant\)/);
    const loanAmountMatch = text.match(/Loan Amount\s+\$([\d,\.]+)/);
    const residentialAddressMatch = text.match(
      /Residential Address\s+(.*?)\s+Post Settlement Address/s
    );
    const postSettlementAddressMatch = text.match(
      /Post Settlement Address\s+(.*?)\s+Contact/s
    );
    const lenderIDMatch = text.match(/Lender ID:\s+([\d]+)/);
    const primaryPurposeMatch = text.match(
      /Primary Purpose\s+(.*?)(?=\s+Product)/
    );
    const productMatch = text.match(/Product\s+(.*?)(?=\s+Loan Term)/);
    const loanTermMatch = text.match(/Loan Term\s+([\d\s\w]+)/);
    const baseAmountMatch = text.match(/Base Amount\s+\$([\d,\.]+)/);
    const repaymentTypeMatch = text.match(/Repayment Type\s+(.*?)(?=\s+Fees)/);
    const securityDetailsMatch = text.match(
      /Security Details\s+(.*?)(?=\s+Transaction)/s
    );
    const securityValueMatch = text.match(/Security Value\s+\$([\d,\.]+)/);

    const extractedDetails = {
      applyOnlineID: applyOnlineIDMatch ? applyOnlineIDMatch[1] : "Not Found",
      applicationID: applicationIDMatch ? applicationIDMatch[1] : "Not Found",
      applicants: [
        primaryApplicantMatch ? primaryApplicantMatch[1].trim() : "Not Found",
        coApplicantMatch ? coApplicantMatch[1].trim() : "Not Found",
      ],
      loanDetails: {
        loanAmount: loanAmountMatch ? loanAmountMatch[1] : "Not Found",
        residentialAddress: residentialAddressMatch
          ? residentialAddressMatch[1].trim()
          : "Not Found",
        postSettlementAddress: postSettlementAddressMatch
          ? postSettlementAddressMatch[1].trim()
          : "Not Found",
        lenderID: lenderIDMatch ? lenderIDMatch[1] : "Not Found",
        primaryPurpose: primaryPurposeMatch
          ? primaryPurposeMatch[1].trim()
          : "Not Found",
        product: productMatch ? productMatch[1].trim() : "Not Found",
        loanTerm: loanTermMatch ? loanTermMatch[1].trim() : "Not Found",
        baseAmount: baseAmountMatch ? baseAmountMatch[1] : "Not Found",
        repaymentType: repaymentTypeMatch
          ? repaymentTypeMatch[1].trim()
          : "Not Found",
        securityDetails: securityDetailsMatch
          ? securityDetailsMatch[1].trim()
          : "Not Found",
        securityValue: securityValueMatch ? securityValueMatch[1] : "Not Found",
      },
    };
    setDetails(extractedDetails);
  };

  const filteredRoles = roles.filter((role) =>
    role.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add Contract</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12">
            <select
              className="form-select"
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <option value="">Select a role...</option>
              {filteredRoles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
          {selectedRole && (
            <div className="col-md-12">
              <input
                type="text"
                className="form-control"
                placeholder="Select"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          )}

          <div
            className={`drag-drop-area ${isDragging ? "dragging" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              border: "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
              borderRadius: "5px",
              marginBottom: "20px",
              backgroundColor: isDragging ? "#f0f0f0" : "white",
            }}
          >
            {file ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                }}
              >
                <div>
                  <strong>File:</strong> {file.name}
                </div>
                <button
                  type="button"
                  onClick={handleRemoveFile}
                  style={{
                    background: "red",
                    color: "#fff",
                    border: "none",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Remove
                </button>
              </div>
            ) : (
              <>
                <p>Drag & drop a PDF file here, or click to upload</p>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  id="fileUpload"
                />
                <label
                  htmlFor="fileUpload"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "blue",
                  }}
                >
                  Browse File
                </label>
              </>
            )}
          </div>
          <hr />
          <div className="col-md-12 text-end">
            <button type="submit" className="btn btn-primary" disabled={!file}>
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddContract;
