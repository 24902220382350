import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo1 from "../../assets/images/gallery/18.png";
import logo2 from "../../assets/images/avatars/02.png";
import { openLoanDetailsPage } from "../../redux/actions";
import { getName } from "../../utils/helper";

const LoanDetails = () => {
  const { openLoanDetails } = useSelector((data) => data?.collections);
  const dispatch = useDispatch();

  const backCustomer = () => {
    dispatch(openLoanDetailsPage(""));
  };
  
  return (
    <main class="main-wrapper">
      <div class="main-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div class="breadcrumb-title pe-3">Loans</div>
          <div class="ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item">
                  <a href="javascript:;">
                    <i class="bx bx-home-alt"></i>
                  </a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Loans Details
                </li>
              </ol>
            </nav>
          </div>
          <div class="ms-auto">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary"
                onClick={backCustomer}
              >
                <i class="bi bi-arrow-left"></i> Back
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-4 d-flex">
            <div class="card w-100">
              <div class="card-body">
                <div class="position-relative">
                  <img src={logo1} class="img-fluid rounded" alt="" />
                  <div class="position-absolute top-100 start-50 translate-middle">
                    <img
                      src={logo2}
                      width="100"
                      height="100"
                      class="rounded-circle raised p-1 bg-white"
                      alt=""
                    />
                  </div>
                </div>
                <div class="text-center mt-5 pt-4">
                  <h4 class="mb-1">
                    {getName(openLoanDetails?.loanRelations[0])}
                  </h4>
                  <p class="mb-0">{openLoanDetails?.loanRelations[0]?.loanApplicant} Customer</p>
                </div>
                {/* <div class="d-flex align-items-center justify-content-center gap-3 my-5">
                  <a
                    href="javascript:;"
                    class="wh-48 bg-linkedin text-white rounded-circle d-flex align-items-center justify-content-center"
                  >
                    <i class="bi bi-linkedin fs-5"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="wh-48 bg-dark text-white rounded-circle d-flex align-items-center justify-content-center"
                  >
                    <i class="bi bi-twitter-x fs-5"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="wh-48 bg-facebook text-white rounded-circle d-flex align-items-center justify-content-center"
                  >
                    <i class="bi bi-facebook fs-5"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="wh-48 bg-pinterest text-white rounded-circle d-flex align-items-center justify-content-center"
                  >
                    <i class="bi bi-youtube fs-5"></i>
                  </a>
                </div> */}
                {/* <div class="d-flex align-items-center justify-content-around">
                  <div class="d-flex flex-column gap-2">
                    <h4 class="mb-0">798</h4>
                    <p class="mb-0">Posts</p>
                  </div>
                  <div class="d-flex flex-column gap-2">
                    <h4 class="mb-0">48K</h4>
                    <p class="mb-0">Following</p>
                  </div>
                  <div class="d-flex flex-column gap-2">
                    <h4 class="mb-0">24.3M</h4>
                    <p class="mb-0">Followers</p>
                  </div>
                </div> */}
              </div>

              <ul className="list-group list-group-flush">
                <li className="list-group-item border-top">
                  <b>Address</b>
                  <br />
                  {openLoanDetails?.loanRelations[0]?.address?.street}
                  {openLoanDetails?.loanRelations[0]?.address?.city}{" "}
                  {openLoanDetails?.loanRelations[0]?.address?.state}{" "}
                  {openLoanDetails?.loanRelations[0]?.address?.country}{" "}
                  {openLoanDetails?.loanRelations[0]?.address?.zipcode}
                </li>
                <li className="list-group-item">
                  <b>Email</b>
                  <br />
                  {openLoanDetails?.loanRelations[0]?.email}
                </li>
                <li className="list-group-item">
                  <b>Phone</b>
                  <br />
                  Mobile : {openLoanDetails?.loanRelations[0]?.mobile}
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 col-lg-8 d-flex">
            <div class="card w-100">
              <div class="card-body">
                <h5 class="mb-3">Send Notes to Customer</h5>
                <textarea
                  class="form-control"
                  placeholder="write somthing"
                  rows="15"
                  cols="6"
                ></textarea>
                <button class="btn btn-filter w-100 mt-3">Add Meesage</button>
              </div>
              {/* <div class="customer-notes mb-3">
                <div class="bg-light mx-3 my-0 rounded-3 p-3">
                  <div class="notes-item">
                    <p class="mb-2">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. of letters, as opposed to using 'Content
                      here, content here.
                    </p>
                    <p class="mb-0 text-end fst-italic text-secondary">
                      10 Apr, 2022
                    </p>
                  </div>
                  <hr class="border-dotted" />
                  <div class="notes-item">
                    <p class="mb-2">
                      Various versions have evolved over the years, sometimes
                    </p>
                    <p class="mb-0 text-end fst-italic text-secondary">
                      15 Apr, 2022
                    </p>
                  </div>
                  <hr />
                  <div class="notes-item">
                    <p class="mb-2">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some
                    </p>
                    <p class="mb-0 text-end fst-italic text-secondary">
                      15 Apr, 2022
                    </p>
                  </div>
                  <hr />
                  <div class="notes-item">
                    <p class="mb-2">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate. quae ab
                      illo inventore veritatis et quasi architecto
                    </p>
                    <p class="mb-0 text-end fst-italic text-secondary">
                      18 Apr, 2022
                    </p>
                  </div>
                  <hr />
                  <div class="notes-item">
                    <p class="mb-2">
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature
                    </p>
                    <p class="mb-0 text-end fst-italic text-secondary">
                      22 Apr, 2022
                    </p>
                  </div>
                  <hr />
                  <div class="notes-item">
                    <p class="mb-2">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo. Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur aut
                      odit aut fugit, sed quia consequuntur magni dolores
                    </p>
                    <p class="mb-0 text-end fst-italic text-secondary">
                      22 Apr, 2022
                    </p>
                  </div>
                  <hr />
                  <div class="notes-item">
                    <p class="mb-2">
                      On the other hand, we denounce with righteous indignation
                      and dislike pleasure of the moment, so blinded by desire,
                      that they cannot foresee the pain and trouble that are
                      bound to ensue; and equal blame belongs to those
                    </p>
                    <p class="mb-0 text-end fst-italic text-secondary">
                      22 Apr, 2022
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="mb-3">Loan Details</h5>
            <div class="product-table">
              <div class="table-responsive white-space-nowrap">
                <table class="table align-middle">
                  <thead class="table-light">
                    <tr>
                      <th>Customer Name</th>
                      <th>Email</th>
                      <th>Applicant Type</th>
                      <th>Loan Amount</th>
                      <th>Loan Type</th>
                      <th>Term</th>
                      <th>Interest Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {openLoanDetails?.loanRelations?.length > 0 &&
                      openLoanDetails?.loanRelations?.map((val, ind) => (
                        <tr>
                          {console.log("valaaa", val)}
                          <td>{getName(val)}</td>
                          <td>{val?.email}</td>
                          <td>
                            <span
                              className={`lable-table rounded font-text2 fw-bold border 
                                ${
                                  val?.loanApplicant === "Primary"
                                    ? "bg-success-subtle text-success border-success-subtle"
                                    : "bg-danger-subtle text-danger border-danger-subtle"
                                }`}
                            >
                              {val?.loanApplicant}
                            </span>
                          </td>

                          <td>{openLoanDetails?.loanAmount}</td>
                          <td>{openLoanDetails?.loanType}</td>
                          <td>{openLoanDetails?.term}</td>
                          <td>{openLoanDetails?.interestRate}</td>
                          {/* <td>
                          <div class="dropdown">
                            <button
                              class="btn btn-sm dropdown-toggle dropdown-toggle-nocaret"
                              type="button"
                              data-bs-toggle="dropdown"
                            >
                              <i class="bi bi-three-dots"></i>
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a class="dropdown-item" href="javascript:;">
                                  <i class="bi bi-eye-fill me-2"></i>View
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="javascript:;">
                                  <i class="bi bi-box-arrow-right me-2"></i>
                                  Export
                                </a>
                              </li>
                              <li class="dropdown-divider"></li>
                              <li>
                                <a
                                  class="dropdown-item text-danger"
                                  href="javascript:;"
                                >
                                  <i class="bi bi-trash-fill me-2"></i>Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoanDetails;
