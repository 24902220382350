import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "react-toastify/dist/ReactToastify.css";
import "./vertical-menu/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css";
import "./vertical-menu/assets/plugins/metismenu/metisMenu.min.css";
import "./vertical-menu/assets/plugins/metismenu/mm-vertical.css";
import "./vertical-menu/assets/plugins/simplebar/css/simplebar.css";
import "./vertical-menu/assets/css/bootstrap.min.css";
import "./vertical-menu/assets/css/bootstrap-extended.css";
import "./vertical-menu/sass/main.css";
import "./vertical-menu/sass/dark-theme.css";
import "./vertical-menu/sass/blue-theme.css";
import "./vertical-menu/sass/semi-dark.css";
import "./vertical-menu/sass/bordered-theme.css";
import "./vertical-menu/sass/responsive.css";
import reducers from './vertical-menu/redux/reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
