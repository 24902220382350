import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "../../Home";
import { getAllCompanyData, getAllEmployeeData } from "../../redux/actions/Api";
import { addCompanyForm, resetResponse } from "../../redux/actions";
import Success from "../../Success";
import { getName } from "../../utils/helper";

const CompanyList = () => {
  const dispatch = useDispatch();
  const { getAllCompany, response } = useSelector((data) => data?.collections);
  const [companyData, setCompanyData] = useState([]);
  const [filteredCompany, setFilteredCompany] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [empData, setEmpData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(addCompanyForm(false));
    // dispatch(getAllEmployeeData());
    dispatch(getAllCompanyData());
  }, []);

  const handleOpenCompany = () => {
    dispatch(addCompanyForm(true));
  };

  useEffect(() => {
    if (response) {
      toast.info(<Success response={response} />, {});
      dispatch(resetResponse());
    }
  }, [response]);

  useEffect(() => {
    if (getAllCompany) {
      setCompanyData(getAllCompany);
      setFilteredCompany(getAllCompany?.data);
    }
  }, [getAllCompany]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredCompany(companyData?.data || []);
    } else {
      const filtered = companyData?.data?.filter((comData) => {
        const nameMatch = getName(comData)
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const emailMatch = comData?.email
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        const mobileMatch = comData?.phoneNumber
          ?.toString()
          .includes(searchQuery);
        const address = comData?.address?.toString().includes(searchQuery);
        const city = comData?.city?.toString().includes(searchQuery);
        const state = comData?.state?.toString().includes(searchQuery);
        const zipCode = comData?.zipcode?.toString().includes(searchQuery);
        return (
          nameMatch ||
          emailMatch ||
          mobileMatch ||
          address ||
          city ||
          state ||
          zipCode
        );
      });
      setFilteredCompany(filtered);
    }
    setCurrentPage(1);
  }, [searchQuery, companyData]);

  const handleEmployeeDetails = (e) => {
    const customerName = e.target.textContent.trim();
    setEmpData(customerName);
    // dispatch(openCustomerDetails(true));
  };

  const totalItems = filteredCompany?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = filteredCompany?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <Home>
      <main class="main-wrapper">
        <div class="main-content">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div class="breadcrumb-title pe-3">Company</div>
            <div class="ps-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 p-0">
                  <li class="breadcrumb-item">
                    <a href="javascript:;">
                      <i class="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Company List
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div class="ms-auto">
              <div class="btn-group">
                <button type="button" class="btn btn-primary">
                  Settings
                </button>
                <button
                  type="button"
                  class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                >
                  {" "}
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                  {" "}
                  <a class="dropdown-item" href="javascript:;">
                    Action
                  </a>
                  <a class="dropdown-item" href="javascript:;">
                    Another action
                  </a>
                  <a class="dropdown-item" href="javascript:;">
                    Something else here
                  </a>
                  <div class="dropdown-divider"></div>{" "}
                  <a class="dropdown-item" href="javascript:;">
                    Separated link
                  </a>
                </div>
              </div>
            </div> */}
          </div>

          <div class="product-count d-flex align-items-center gap-3 gap-lg-4 mb-4 fw-bold flex-wrap font-text1">
            <a href="javascript:;">
              <span class="me-1">All</span>
              <span class="text-secondary">(85472)</span>
            </a>
            <a href="javascript:;">
              <span class="me-1">New</span>
              <span class="text-secondary">(145)</span>
            </a>
            <a href="javascript:;">
              <span class="me-1">Active</span>
              <span class="text-secondary">(89)</span>
            </a>
            <a href="javascript:;">
              <span class="me-1">Inactive</span>
              <span class="text-secondary">(5872)</span>
            </a>
            {/* <a href="javascript:;">
              <span class="me-1">Subscribers</span>
              <span class="text-secondary">(163)</span>
            </a>
            <a href="javascript:;">
              <span class="me-1">Top Reviews</span>
              <span class="text-secondary">(8)</span>
            </a> */}
          </div>

          <div class="row g-3">
            <div class="col-auto">
              <div class="col-auto">
                <div class="position-relative">
                  <input
                    className="form-control px-5"
                    type="search"
                    placeholder="Search Companys"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <span class="material-icons-outlined position-absolute ms-3 translate-middle-y start-0 top-50 fs-5">
                    search
                  </span>
                </div>
              </div>
            </div>
            <div class="col-auto flex-grow-1 overflow-auto">
              <div class="btn-group position-static">
                {/* <div class="btn-group position-static">
                    <button type="button" class="btn btn-filter dropdown-toggle px-4" data-bs-toggle="dropdown" aria-expanded="false">
                      Country
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="javascript:;">Action</a></li>
                      <li><a class="dropdown-item" href="javascript:;">Another action</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><a class="dropdown-item" href="javascript:;">Something else here</a></li>
                    </ul>
                  </div>
                  <div class="btn-group position-static">
                    <button type="button" class="btn btn-filter dropdown-toggle px-4" data-bs-toggle="dropdown" aria-expanded="false">
                      Source
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="javascript:;">Action</a></li>
                      <li><a class="dropdown-item" href="javascript:;">Another action</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><a class="dropdown-item" href="javascript:;">Something else here</a></li>
                    </ul>
                  </div> */}
                <div class="btn-group position-static">
                  <button
                    type="button"
                    class="btn btn-filter dropdown-toggle px-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    More Filters
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="javascript:;">
                        All Company
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="javascript:;">
                        Active Company
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" href="javascript:;">
                        Inactive Company
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex align-items-center gap-2 justify-content-lg-end">
                {/* <button class="btn btn-filter px-4">
                  <i class="bi bi-box-arrow-right me-2"></i>Export
                </button> */}
                <button
                  class="btn btn-primary px-4"
                  onClick={handleOpenCompany}
                >
                  <i class="bi bi-plus-lg me-2"></i>Add Company
                </button>
              </div>
            </div>
          </div>

          <div class="card mt-4">
            <div class="card-body">
              <div class="customer-table">
                <div class="table-responsive white-space-nowrap">
                  <table class="table align-middle">
                    <thead class="table-light">
                      <tr>
                        <th>Company Name </th>
                        <th>Admin Name </th>
                        <th>Email</th>
                        {/* <th>Mobile Number</th> */}
                        <th>Address</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {currentItems?.length > 0 || companyData === undefined ? (
                      <tbody>
                        {currentItems?.map((val, index) => (
                          <tr key={index}>
                            <td>
                              <a
                                class="d-flex align-items-center gap-3"
                                href="#"
                              >
                                <p
                                  className="mb-0 customer-name fw-bold"
                                  onClick={handleEmployeeDetails}
                                >
                                  {val?.companyName}
                                </p>
                              </a>
                            </td>
                            <td>
                              <a
                                class="d-flex align-items-center gap-3"
                                href="#"
                              >
                                <p className="mb-0 customer-name fw-bold">
                                  {val?.users[0]?.firstName}
                                </p>
                              </a>
                            </td>
                            <td>
                              <a
                                href={`mailto:${val?.users[0]?.email}`}
                                className="font-text1"
                              >
                                {val?.users[0]?.email}
                              </a>
                            </td>
                            {/* <td>{emp.Emp_PhoneNumber}</td> */}

                            <td>
                              {" "}
                              {val?.address} {val?.city} {val?.state}{" "}
                              {val?.country} {val?.zipcode}
                            </td>

                            <td>{val?.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : filteredCompany?.length === 0 ? (
                      <div className="align-items-center p-5">
                        <p>No Records Founds</p>
                      </div>
                    ) : (
                      <div className="align-items-center p-5">
                        <p>Loading...</p>
                      </div>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3 p-3">
              <div>
                <label>
                  Items per page:{" "}
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </label>
              </div>
              <div>
                <button
                  className={`btn btn-secondary me-2 nav-button ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className={`btn btn-secondary ms-2 nav-button next-button ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Home>
  );
};

export default CompanyList;
