import React, { useEffect, useState } from "react";
import Home from "../../Home";
import { selectedData } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import NewsletterUsers from "./NewsletterUsers";

const SmsUsers = () => {
  const dispatch = useDispatch();
  const { allSelectedData, getAllCustomer } = useSelector(
    (data) => data?.collections
  );
  const [model, setModel] = useState("");
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState([]);

  useEffect(() => {
    dispatch(selectedData());
  }, []);

  const handleModelChange = (e) => {
    setModel(e.target.value);
  };

  const handleSendToCustomer = () => {
    setShowNewsletter(true);
  };

  useEffect(() => {
    if (getAllCustomer?.data && allSelectedData?.length > 0) {
      const selectedDetails = getAllCustomer.data.filter((val) =>
        allSelectedData.includes(val.cust_id)
      );
      setSelectedCustomerDetails(selectedDetails);
    }
  }, [getAllCustomer, allSelectedData]);

  const handleSubmitData = () => {
    console.log("send all data", model, selectedCustomerDetails);
  };


  return (
    <Home>
      <main className="main-wrapper">
        <div className="main-content">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div class="breadcrumb-title pe-3">SMS</div>
            <div class="ps-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 p-0">
                  <li class="breadcrumb-item">
                    <a href="javascript:;">
                      <i class="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Messages
                  </li>
                </ol>
              </nav>
            </div>
            <div class="ms-auto">
              <div class="btn-group">
                <button type="button" class="btn btn-primary">
                  Settings
                </button>
                <button
                  type="button"
                  class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                >
                  {" "}
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                  {" "}
                  <a class="dropdown-item" href="javascript:;">
                    Action
                  </a>
                  <a class="dropdown-item" href="javascript:;">
                    Separated link
                  </a>
                </div>
              </div>
            </div>
          </div>
          <textarea
            className="form-control"
            placeholder="Enter your message..."
            value={model}
            onChange={handleModelChange}
            rows={4}
          />

          {showNewsletter && model && <NewsletterUsers />}

          {allSelectedData?.length > 0 && model ? (
            <div className="col-md-12 text-end pt-3">
              {" "}
              <button onClick={handleSubmitData} className="btn btn-primary">
                Send
              </button>
            </div>
          ) : (
            <div className="col-md-12 text-end pt-3">
              {" "}
              <button
                onClick={handleSendToCustomer}
                className="btn btn-primary"
                disabled={!model}
              >
                Select Customers
              </button>
            </div>
          )}
        </div>
      </main>
    </Home>
  );
};

export default SmsUsers;
