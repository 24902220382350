import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound() {
  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", "blue-theme");
  }, []);

  return (
    <div class="pt-5">
      <div class="container pt-5">
        <div class="row pt-5">
          <div class="col-lg-12">
            <div class="text-center error-pages">
              <h1 class="error-title text-white mb-3">404</h1>
              <h2 class="error-sub-title text-white">404 NOT FOUND</h2>

              <p class="error-message text-white text-uppercase">
                SORRY, AN ERROR HAS OCCURED, REQUESTED PAGE NOT FOUND!
              </p>

              <div class="mt-4 d-flex align-items-center justify-content-center gap-3">
                <Link to="/" className="btn btn-grd-danger rounded-5 px-4">
                  <i class="bi bi-house-fill me-2"></i> Go Back Home
                </Link>
              </div>

              <div class="mt-4">
                <p class="text-light">
                  Copyright © 2024 | All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
