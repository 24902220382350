import axios from "axios";


export const getName = (string) => {
  if (!string) return ""; 
  let str = "";
  if (string?.name) {
    str += string.name.charAt(0).toUpperCase() + string.name.slice(1);
  }
  if (string?.firstName) {
    str += string.firstName.charAt(0).toUpperCase() + string.firstName.slice(1);
  }
  if (string?.middleName) {
    str += " " + string.middleName.charAt(0).toUpperCase() + string.middleName.slice(1);
  }
  if (string?.lastName) {
    str += " " + string.lastName.charAt(0).toUpperCase() + string.lastName.slice(1);
  }
  if (!str && string?.name) {
    str = string.name.charAt(0).toUpperCase() + string.name.slice(1);
  }
  return str.trim();
};


export const getUnauthedAxios = () =>
  axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

export const getToken = () => {
  let accessToken = "";
  let token = localStorage.getItem("access_token");
  if (token) {
    let accessTokenObj = token.split("##");
    accessToken = accessTokenObj[0];
  }
  return accessToken;
};

export const getAxios = () => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return axios.create({ headers });
};

export const formatDate = (dob) => {
  if (!dob) return "";
  const date = new Date(dob);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
