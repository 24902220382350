import React, { useState } from "react";
// import headerlogo from "./assets/images/avatars/01.png";
// import headerlogo2 from "./assets/images/avatars/02.png";
import county from "./assets/images/county/02.png";
import profile from "./assets/images/avatars/01.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "./assets/images/logoNew.png";

const Header = ({ toggleSidebar }) => {
  const { birthDayData, roleName } = useSelector((data) => data?.collections);
  const navigate = useNavigate();

  const handleRedirect = (val) => {
    if (val === "birthdays") {
      navigate("/marketing/birthdays");
    } else if (val === "dashboard") {
      navigate("/dashboard");
    } else if (val === "help") {
      navigate("/help");
    } else if (val === "signout") {
      localStorage.removeItem("access_token");
      navigate("/signin");
    }
  };

  return (
    <header class="top-header">
      <nav class="navbar navbar-expand align-items-center justify-content-between gap-4 border-bottom">
        <div class="search-bar">
          <div class="btn-toggle">
            <a href="#" onClick={toggleSidebar}>
              <i class="material-icons-outlined">menu</i>
            </a>
          </div>
        </div>
        <ul class="navbar-nav gap-1 nav-right-links align-items-center">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle dropdown-toggle-nocaret"
              href="avascript:;"
              data-bs-toggle="dropdown"
            >
              <img src={county} width="22" alt="" />
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  class="dropdown-item d-flex align-items-center py-2"
                  href="javascript:;"
                >
                  <img src="assets/images/county/01.png" width="20" alt="" />
                  <span class="ms-2">English</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center py-2"
                  href="javascript:;"
                >
                  <img src="assets/images/county/02.png" width="20" alt="" />
                  <span class="ms-2">Catalan</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center py-2"
                  href="javascript:;"
                >
                  <img src="assets/images/county/03.png" width="20" alt="" />
                  <span class="ms-2">French</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center py-2"
                  href="javascript:;"
                >
                  <img src="assets/images/county/04.png" width="20" alt="" />
                  <span class="ms-2">Belize</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center py-2"
                  href="javascript:;"
                >
                  <img src="assets/images/county/05.png" width="20" alt="" />
                  <span class="ms-2">Colombia</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center py-2"
                  href="javascript:;"
                >
                  <img src="assets/images/county/06.png" width="20" alt="" />
                  <span class="ms-2">Spanish</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center py-2"
                  href="javascript:;"
                >
                  <img src="assets/images/county/07.png" width="20" alt="" />
                  <span class="ms-2">Georgian</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center py-2"
                  href="javascript:;"
                >
                  <img src="assets/images/county/08.png" width="20" alt="" />
                  <span class="ms-2">Hindi</span>
                </a>
              </li>
            </ul>
          </li>
          {roleName?.role !== "SUPER_ADMIN" && birthDayData && (
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative"
                data-bs-auto-close="outside"
                data-bs-toggle="dropdown"
                href="javascript:;"
              >
                <i class="material-icons-outlined">notifications</i>
                {birthDayData && <span class="badge-notify">1</span>}
              </a>
              <div class="dropdown-menu dropdown-notify dropdown-menu-end shadow">
                <div class="px-3 py-1 d-flex align-items-center justify-content-between border-bottom">
                  <h5 class="notiy-title mb-0">Notifications</h5>
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle dropdown-toggle-nocaret option"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span class="material-icons-outlined">more_vert</span>
                    </button>
                    <div class="dropdown-menu dropdown-option dropdown-menu-end shadow">
                      <div>
                        <a
                          class="dropdown-item d-flex align-items-center gap-2 py-2"
                          href="javascript:;"
                        >
                          <i class="material-icons-outlined fs-6">
                            inventory_2
                          </i>
                          Archive All
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item d-flex align-items-center gap-2 py-2"
                          href="javascript:;"
                        >
                          <i class="material-icons-outlined fs-6">done_all</i>
                          Mark all as read
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item d-flex align-items-center gap-2 py-2"
                          href="javascript:;"
                        >
                          <i class="material-icons-outlined fs-6">mic_off</i>
                          Disable Notifications
                        </a>
                      </div>
                      <div>
                        <a
                          class="dropdown-item d-flex align-items-center gap-2 py-2"
                          href="javascript:;"
                        >
                          <i class="material-icons-outlined fs-6">grade</i>
                          What's new ?
                        </a>
                      </div>
                      <div>
                        <hr class="dropdown-divider" />
                      </div>
                      <div>
                        <a
                          class="dropdown-item d-flex align-items-center gap-2 py-2"
                          href="javascript:;"
                        >
                          <i class="material-icons-outlined fs-6">
                            leaderboard
                          </i>
                          Reports
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="notify-list">
                  <div>
                    <a
                      class="dropdown-item border-bottom py-2"
                      onClick={() => handleRedirect("birthdays")}
                    >
                      <div class="d-flex align-items-center gap-3 p-2">
                        <div class="">
                          <h5 class="notify-title">You have Birthdays.</h5>
                          <p class="mb-0 notify-desc">
                            Many congtars Birthday Person.You have won the
                            gifts.
                          </p>
                          <p class="mb-0 notify-time">
                            Today {birthDayData?.length} Birthdays.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          )}

          <li class="nav-item dropdown">
            <a
              href="javascrpt:;"
              class="dropdown-toggle dropdown-toggle-nocaret"
              data-bs-toggle="dropdown"
            >
              <img
                src={roleName?.role === "SUPER_ADMIN" ? logo : profile}
                class="rounded-circle p-1 border"
                width="45"
                height="45"
                alt=""
              />
            </a>
            <div class="dropdown-menu dropdown-user dropdown-menu-end shadow">
              <a class="dropdown-item  gap-2 py-2" href="javascript:;">
                <div class="text-center">
                  <img
                    src={roleName?.role === "SUPER_ADMIN" ? logo : profile}
                    class="rounded-circle p-1 shadow mb-3"
                    width="90"
                    height="90"
                    alt=""
                  />
                  {roleName?.role === "SUPER_ADMIN" ? (
                    <h5 class="user-name mb-0 fw-bold">MDS</h5>
                  ) : (
                    <h5 class="user-name mb-0 fw-bold">Hello, Jhon</h5>
                  )}
                </div>
              </a>
              <hr class="dropdown-divider" />
              <a
                class="dropdown-item d-flex align-items-center gap-2 py-2"
                href="#"
              >
                <i class="material-icons-outlined">person_outline</i>Profile
              </a>

              <a
                class="dropdown-item d-flex align-items-center gap-2 py-2"
                href="#"
                onClick={() => handleRedirect("dashboard")}
              >
                <i class="material-icons-outlined">home</i>Dashboard
              </a>
              <a
                class="dropdown-item d-flex align-items-center gap-2 py-2"
                href="#"
                onClick={() => handleRedirect("help")}
              >
                <i class="material-icons-outlined">help_outline</i>Help
              </a>
              <hr class="dropdown-divider" />
              <a
                class="dropdown-item d-flex align-items-center gap-2 py-2"
                href="#"
                onClick={() => handleRedirect("signout")}
              >
                <i class="material-icons-outlined">logout</i>
                Sign Out
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};
export default Header;
