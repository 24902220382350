import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomerData } from "./redux/actions/Api";
import { birthDateNotifi } from "./redux/actions";
import AddCompany from "./components/company/AddCompany";
import AddCustomer from "./components/customer/AddCustomer";
import AddContract from "./components/customer/AddContract";
import AddEmployee from "./components/employee/AddEmployee";
import AddLoanDetails from "./components/loan/AddLoanDetails";
import AddSecondaryCustomer from "./components/customer/AddSecondaryCustomer";

const Home = ({ children }) => {
  const dispatch = useDispatch();
  const { addCustomers, addContract, addEmployee,roleName, getAllCustomer,openCompanyForm,openLoanAdd, secondryCustData } =
    useSelector((data) => data?.collections);

  useEffect(() => {
    if (!getAllCustomer && roleName?.role ==="ADMIN") {
      dispatch(getAllCustomerData());
    }
  }, []);

  useEffect(() => {
    if (getAllCustomer && getAllCustomer?.data?.length > 0) {
      let filtered = getAllCustomer?.data;
      const today = new Date().toISOString().slice(5, 10);
      filtered = getAllCustomer?.data?.filter(
        (cust) => cust?.cust_dob?.slice(5, 10) === today
      );
      dispatch(birthDateNotifi(filtered));
    }
  }, [getAllCustomer]);

  // useEffect(() => {
  //   document.documentElement.setAttribute("data-bs-theme", "blue-theme");
  // }, []);


  return (
    <div>
      <Sidebar />
      {addCustomers && <AddCustomer />}
      {addEmployee && <AddEmployee />}
      {openCompanyForm && <AddCompany />}
      {addContract && <AddContract />}
      {openLoanAdd && <AddLoanDetails />}
      {secondryCustData && <AddSecondaryCustomer />}
      {children && children}
    </div>
  );
};

export default Home;
