import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  PATHS,
  PrivateRoute,
  PublicRoute,
  TOAST_TIMER,
} from "./vertical-menu/utils/index";
import Signin from "./vertical-menu/Signin";
import Home from "./vertical-menu/Home";
import Dashboard from "./vertical-menu/Dashboard";
import NotFound from "./vertical-menu/error/NotFound";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import ThemeCustomizer from "./vertical-menu/ThemeCustomizer";

import Signup from "./vertical-menu/Signup";
import CompanyList from "./vertical-menu/components/company/CompanyList";
import CustomersList from "./vertical-menu/components/customer/CustomersList";
import EmployeeList from "./vertical-menu/components/employee/EmployeeList";
import Newsletter from "./vertical-menu/components/marketing/Newsletter";
import SmsUsers from "./vertical-menu/components/marketing/SmsUsers";
import BirthdayEditor from "./vertical-menu/components/marketing/BirthdayEditor";
import PdfReader from "./vertical-menu/components/documents/PdfUpload";
import Help from "./vertical-menu/components/documents/Help";
import Reports from "./vertical-menu/components/documents/Reports";
import VerifyOtp from "./vertical-menu/VerifyOtp";
import LoanList from "./vertical-menu/components/loan/LoanList";

const App = () => {
  const { openThemeSettingModal } = useSelector((data) => data?.collections);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={TOAST_TIMER}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {openThemeSettingModal && <ThemeCustomizer />}
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={PATHS.USERSIGNIN} replace />}
          />
          <Route
            path={PATHS.USERSIGNIN}
            element={
              <PublicRoute restricted={true}>
                <Signin />
              </PublicRoute>
            }
          />
          {/* <Route
          path={PATHS.USERSIGNUP}
          element={
            <PublicRoute restricted={true}>
              <Signup />
            </PublicRoute>
          }
        /> */}
          <Route
            path={PATHS.HOME}
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.DASHBOARD}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.COMPANY}
            element={
              <PrivateRoute>
                <CompanyList />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.EMPLOYEE}
            element={
              <PrivateRoute>
                <EmployeeList />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.CUSTOMER}
            element={
              <PrivateRoute>
                <CustomersList />
              </PrivateRoute>
            }
          />

          <Route
            path={PATHS.LOANDETAILS}
            element={
              <PrivateRoute>
                <LoanList />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.PDFUPLOAD}
            element={
              <PrivateRoute>
                <PdfReader />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.HELP}
            element={
              <PrivateRoute>
                <Help />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.MARKETING}
            element={
              <PrivateRoute>
                <Newsletter />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.REPORTS}
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.SMS}
            element={
              <PrivateRoute>
                <SmsUsers />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.BIRTHDAY}
            element={
              <PrivateRoute>
                <BirthdayEditor />
              </PrivateRoute>
            }
          />
          <Route
            path={PATHS.VERIFYOTP}
            element={
              <PublicRoute>
                <VerifyOtp />
              </PublicRoute>
            }
          />
          <Route
            path="*"
            element={
              <PublicRoute>
                <NotFound />
              </PublicRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
