import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { addLoanForm } from "../../redux/actions";
import { MSG } from "../../utils/messages";
import { EMAIL_REGEX, NAME_REGEX, NUM_REGEX } from "../../utils/Regex";
import {
  addEmployeeData,
  getAllCustomerData,
  getLoanProduct,
  postLoanDetails,
} from "../../redux/actions/Api";
import { getName } from "../../utils/helper";
import AddCustomer from "../customer/AddCustomer";
import AddSecondaryCustomer from "../customer/AddSecondaryCustomer";

const AddLoanDetails = () => {
  const dispatch = useDispatch();
  const { getAllCustomer, response, LoanProductsData, openLoanAdd, LoanData } =
    useSelector((data) => data?.collections);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onTouched" });

  const [show, setShow] = useState(true);
  const [customers, setCustomers] = useState([]);

  const loanCategory = ["Home Loan", "Personal Loan", "Business Loan"];
  const loanType = [
    "Secured Loan",
    "Unsecured Loan",
    "Fixed-Rate Loan",
    "Variable-Rate Loan",
  ];
  const aggregatorList = ["Finsure", "Connective", "Mortgage", "LoanMarket"];
  const bankList = [
    "State Bank of India",
    "HDFC Bank",
    "ICICI Bank",
    "Axis Bank",
    "Kotak Mahindra Bank",
    "Punjab National Bank",
    "Bank of Baroda",
    "IndusInd Bank",
    "Yes Bank",
    "Federal Bank",
  ];
  const repaymentOptions = ["Monthly", "Quarterly", "Yearly"];
  const employmentTypes = ["Salaried", "Self-Employed", "Freelancer"];


  useEffect(() => {
    if (!getAllCustomer) {
      dispatch(getAllCustomerData());
    }
  }, [getAllCustomer]);

  useEffect(() => {
    if (!LoanProductsData) {
      dispatch(getLoanProduct());
    }
  }, [LoanProductsData]);

  useEffect(() => {
    if (getAllCustomer) {
      setCustomers(getAllCustomer);
    }
  }, [getAllCustomer]);

  const handleClose = () => {
    setShow(false);
    dispatch(addLoanForm(""));
  };

  const onSubmit = (data) => {
    const payload = {
      customerId: openLoanAdd?.id,
      productId: data.loanCategory,
      employeeId: 7,
      loanType: data.loanType,
      loanAmount: data.loanAmount,
      aggregator: data.aggregator,
      bank: data.bankName,
      term: data.loanTerm,
      rePaymentTerm: data.repaymentTerm,
      interestRate: data.interestRate,
      loan: "Home Loan",
      purpose: data.purpose,
      employmentType: data.employmentType,
      companyName: data.companyName,
      designation: data.designation,
      monthlyIncome: data.monthlyIncome,
      joiningDate: data.joiningDate,
      settlementDate: data.settlementDate,
    };
    dispatch(postLoanDetails(payload));

    reset();
  };

  return (
    <>
      {!LoanData?.success === true ? (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Loans Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
              <h6 style={{ fontWeight: 700 }}>Loan Details</h6>
              <div className="col-md-4">
                <label htmlFor="loanCategory" className="form-label">
                  Loan Category
                </label>
                <select
                  id="loanCategory"
                  className={`form-control ${
                    errors.loanCategory ? "is-invalid" : ""
                  }`}
                  {...register("loanCategory", { required: MSG.LOANREQ })}
                >
                  <option value="">Select a loan category...</option>
                  {LoanProductsData?.data?.map((type, index) => (
                    <option key={index} value={type?.id}>
                      {type?.loan_type}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {errors.loanCategory?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="loanType" className="form-label">
                  Loan Type
                </label>
                <select
                  id="loanType"
                  className={`form-control ${
                    errors.loanType ? "is-invalid" : ""
                  }`}
                  {...register("loanType", { required: MSG.LOANTYPEREQ })}
                >
                  <option value="">Select a loan type...</option>
                  {loanType.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {errors.loanType?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="aggregator" className="form-label">
                  Aggregator
                </label>
                <select
                  id="aggregator"
                  className={`form-control ${
                    errors.aggregator ? "is-invalid" : ""
                  }`}
                  {...register("aggregator", { required: MSG.AGGREREQ })}
                >
                  <option value="">Select a loan aggregator...</option>
                  {aggregatorList.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {errors.aggregator?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="bankName" className="form-label">
                  Bank Name
                </label>
                <select
                  id="bankName"
                  className={`form-control ${
                    errors.bankName ? "is-invalid" : ""
                  }`}
                  {...register("bankName", { required: MSG.BANKREQ })}
                >
                  <option value="">Select a bank...</option>
                  {bankList.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {errors.bankName?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="loanAmount" className="form-label">
                  Loan Amount<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="loanAmount"
                  placeholder="Loan Amount"
                  className={`form-control ${
                    errors.loanAmount ? "is-invalid" : ""
                  }`}
                  {...register("loanAmount", {
                    required: MSG.LOANAMREQ,
                    pattern: { value: /^[0-9]+$/, message: MSG.NUMREQ },
                  })}
                />
                <div className="invalid-feedback">
                  {errors.loanAmount?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="interestRate" className="form-label">
                  Interest Rate (%)<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="interestRate"
                  placeholder="Interest Rate"
                  className={`form-control ${
                    errors.interestRate ? "is-invalid" : ""
                  }`}
                  {...register("interestRate", {
                    required: MSG.INTRREQ,
                    pattern: { value: /^[0-9.]+$/, message: MSG.NUMREQ },
                  })}
                />
                <div className="invalid-feedback">
                  {errors.interestRate?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="loanTerm" className="form-label">
                  Loan Term (Months)<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="loanTerm"
                  placeholder="Loan Term"
                  className={`form-control ${
                    errors.loanTerm ? "is-invalid" : ""
                  }`}
                  {...register("loanTerm", {
                    required: MSG.LOANTEREQ,
                    pattern: { value: /^[0-9]+$/, message: MSG.NUMREQ },
                  })}
                />
                <div className="invalid-feedback">
                  {errors.loanTerm?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="repaymentTerm" className="form-label">
                  Repayment Term
                </label>
                <select
                  id="repaymentTerm"
                  className={`form-control ${
                    errors.repaymentTerm ? "is-invalid" : ""
                  }`}
                  {...register("repaymentTerm", { required: MSG.REPAYREQ })}
                >
                  <option value="">Select a repayment term...</option>
                  {repaymentOptions.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {errors.repaymentTerm?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="purpose" className="form-label">
                  Purpose<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="purpose"
                  placeholder="Purpose"
                  className={`form-control ${
                    errors.purpose ? "is-invalid" : ""
                  }`}
                  {...register("purpose", {
                    required: MSG.PURPREQ,
                  })}
                />
                <div className="invalid-feedback">
                  {errors.purpose?.message}
                </div>
              </div>

              <h6 style={{ fontWeight: 700 }}>Working Details</h6>

              <div className="col-md-4">
                <label htmlFor="employmentType" className="form-label">
                  Employment Type
                </label>
                <select
                  id="employmentType"
                  className={`form-control ${
                    errors.employmentType ? "is-invalid" : ""
                  }`}
                  {...register("employmentType", { required: MSG.EMPLREQ })}
                >
                  <option value="">Select employment type...</option>
                  {employmentTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {errors.employmentType?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="companyName" className="form-label">
                  Company Name<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="companyName"
                  placeholder="Company Name"
                  className={`form-control ${
                    errors.companyName ? "is-invalid" : ""
                  }`}
                  {...register("companyName", { required: MSG.COMPREQ })}
                />
                <div className="invalid-feedback">
                  {errors.companyName?.message}
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="designation" className="form-label">
                  Designation<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="designation"
                  placeholder="Designation"
                  className={`form-control ${
                    errors.designation ? "is-invalid" : ""
                  }`}
                  {...register("designation", { required: MSG.DESREQ })}
                />
                <div className="invalid-feedback">
                  {errors.designation?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="monthlyIncome" className="form-label">
                  Monthly Income<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="monthlyIncome"
                  placeholder="Monthly Income"
                  className={`form-control ${
                    errors.monthlyIncome ? "is-invalid" : ""
                  }`}
                  {...register("monthlyIncome", {
                    required: MSG.MINCREQ,
                    pattern: { value: /^[0-9]+$/, message: MSG.NUMREQ },
                  })}
                />
                <div className="invalid-feedback">
                  {errors.monthlyIncome?.message}
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="joiningDate" className="form-label">
                  Company Joining Date<span className="mandatoryField">*</span>
                </label>
                <input
                  type="date"
                  id="joiningDate"
                  className={`form-control ${
                    errors.joiningDate ? "is-invalid" : ""
                  }`}
                  {...register("joiningDate", {
                    required: MSG.JOINREQ,
                  })}
                />
                <div className="invalid-feedback">
                  {errors.joiningDate?.message}
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="settlementDate" className="form-label">
                  Loan Settlement Date<span className="mandatoryField">*</span>
                </label>
                <input
                  type="date"
                  id="settlementDate"
                  className={`form-control ${
                    errors.settlementDate ? "is-invalid" : ""
                  }`}
                  {...register("settlementDate", {
                    required: MSG.SETTREQ,
                  })}
                />
                <div className="invalid-feedback">
                  {errors.settlementDate?.message}
                </div>
              </div>

              <hr />
              <div className="col-md-12 text-end">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ms-2"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      ) : (
        <AddSecondaryCustomer />
      )}
    </>
  );
};

export default AddLoanDetails;
