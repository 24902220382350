import React, { useState } from "react";
import pdfToText from "react-pdftotext";
import Home from "../../Home";

const PdfReader = () => {
  const [file, setFile] = useState(null);
  const [textData, setTextData] = useState("");
  const [details, setDetails] = useState({
    applyOnlineID: "",
    applicationID: "",
    applicants: [],
    loanDetails: "",
  });
  const [isDragging, setIsDragging] = useState(false);

  const handleFile = (selectedFile) => {
    setFile(selectedFile);
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      handleFile(selectedFile);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      handleFile(selectedFile);
    }
  };

  const handleExtractDetails = () => {
    if (file) {
      pdfToText(file)
        .then((text) => {
          setTextData(text);
          extractDetails(text);
        })
        .catch((error) =>
          console.error("Failed to extract text from PDF", error)
        );
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setTextData("");
    setDetails({
      applyOnlineID: "",
      applicationID: "",
      applicants: [],
      loanDetails: "",
    });
  };

  const extractDetails = (text) => {
    const applyOnlineIDMatch = text.match(/ApplyOnline ID:\s+([\w\-]+)/);
    const applyOnlineID = applyOnlineIDMatch
      ? applyOnlineIDMatch[1]
      : "Not Found";
    const applicationIDMatch = text.match(/Application ID:\s+([\w\-]+)/);
    const applicationID = applicationIDMatch
      ? applicationIDMatch[1]
      : "Not Found";
    const applicantsMatch = text.match(/Applicant:\s+(.*?)(?=Loan Details)/s);
    const applicants = applicantsMatch
      ? applicantsMatch[1]
          .split(/\n/)
          .map((item) => item.trim())
          .filter(Boolean)
      : [];

    const loanDetailsMatch = text.match(
      /Loan Details:\s+(.*?)(?=Security:|Lender ID)/s
    );
    const loanDetails = loanDetailsMatch
      ? loanDetailsMatch[1].trim()
      : "Not Found";

    setDetails({
      applyOnlineID,
      applicationID,
      applicants,
      loanDetails,
    });
  };

  return (
    <Home>
      <main className="main-wrapper">
        <div className="main-content">
          <div
            className={`drag-drop-area ${isDragging ? "dragging" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              border: "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
              borderRadius: "5px",
              marginBottom: "20px",
              backgroundColor: isDragging ? "#f0f0f0" : "white",
            }}
          >
            <p>Drag and drop a PDF file here, or click to upload</p>
            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              id="fileUpload"
            />
            <label htmlFor="fileUpload" style={{ cursor: "pointer" }}>
              Browse File
            </label>
          </div>

          {file && (
            <div style={{ marginBottom: "20px" }}>
              <p>
                <strong>Selected File:</strong> {file.name}
              </p>
              <button onClick={handleExtractDetails} className="btn btn-primary">
                Submit
              </button>
              <button
                onClick={handleRemoveFile}
                className="btn btn-secondary"
                style={{ marginLeft: "10px" }}
              >
                Remove
              </button>
            </div>
          )}

          <hr />

          <h2>Extracted Details</h2>
          <p>
            <strong>ApplyOnline ID:</strong> {details.applyOnlineID}
          </p>
          <p>
            <strong>Application ID:</strong> {details.applicationID}
          </p>
          <strong>Applicant:</strong>
          <ul>
            {details.applicants.map((applicant, index) => (
              <li key={index}>{applicant}</li>
            ))}
          </ul>
          <p>
            <strong>Loan Details:</strong> {details.loanDetails}
          </p>
        </div>
      </main>
    </Home>
  );
};

export default PdfReader;
