import React, { useEffect, useState } from "react";
import avatars from "../../assets/images/avatars/01.png";
import { useDispatch, useSelector } from "react-redux";
import { addCustomersForm, resetResponse, selectedData } from "../../redux/actions";
import Home from "../../Home";
import { getName } from "../../utils/helper";
import { getAllCustomerData } from "../../redux/actions/Api";

const NewsletterUsers = () => {
  const dispatch = useDispatch();
  const { getAllCustomer } = useSelector((data) => data?.collections);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filterType, setFilterType] = useState("all");

  useEffect(() => {
    dispatch(addCustomersForm(false));
    dispatch(getAllCustomerData());
  }, []);

  useEffect(() => {
    if (getAllCustomer) {
      setCustomers(getAllCustomer);
      setFilteredCustomers(getAllCustomer?.data);
    }
  }, [getAllCustomer]);

  useEffect(() => {
    if (searchQuery?.trim() === "") {
      setFilteredCustomers(customers?.data || []);
    } else {
      const filtered = customers?.data?.filter((customer) => {
        const nameMatch = getName(customer)
          .toLowerCase()
          .includes(searchQuery?.toLowerCase());
        const emailMatch = customer?.cust_email
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase());
        const mobileMatch = customer?.cust_mobileNumber
          ?.toString()
          .includes(searchQuery);
        const address = customer?.cust_address
          ?.toString()
          .includes(searchQuery);
        const city = customer?.city?.toString().includes(searchQuery);
        const state = customer?.state?.toString().includes(searchQuery);
        const zipCode = customer?.zipcode?.toString().includes(searchQuery);

        return (
          nameMatch ||
          emailMatch ||
          mobileMatch ||
          address ||
          city ||
          state ||
          zipCode
        );
      });
      setFilteredCustomers(filtered);
    }
    setCurrentPage(1);
  }, [searchQuery, customers]);

  const totalItems = filteredCustomers?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = filteredCustomers?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedCustomers(filteredCustomers?.map((cust) => cust.cust_id));
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleSelectCustomer = (customerId) => {
    let updatedSelection;
    if (selectedCustomers.includes(customerId)) {
      updatedSelection = selectedCustomers.filter((id) => id !== customerId);
    } else {
      updatedSelection = [...selectedCustomers, customerId];
    }

    setSelectedCustomers(updatedSelection);
    setSelectAll(updatedSelection.length === filteredCustomers.length);
  };

  useEffect(() => {
    if (!customers?.data) return;
    let filtered = customers?.data;

    if (filterType === "active") {
      filtered = customers?.data?.filter(
        (cust) => cust?.cust_status === "Active"
      );
    } else if (filterType === "inactive") {
      filtered = customers?.data?.filter(
        (cust) => cust?.cust_status === "Inactive"
      );
    }
    setFilteredCustomers(filtered);
    setSelectAll(false);
    setSelectedCustomers([]);
    setCurrentPage(1);
  }, [filterType, customers]);

  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  useEffect(() => {
    if (selectedCustomers) {
      dispatch(selectedData(selectedCustomers));
    }
  }, [selectedCustomers]);

  return (
    <Home>
        <div className="card mt-4">
          <div className="card-body">
            <div class="row g-3">
              <div class="col-auto">
                <div class="position-relative">
                  <input
                    className="form-control px-5"
                    type="search"
                    placeholder="Search Customers"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <span class="material-icons-outlined position-absolute ms-3 translate-middle-y start-0 top-50 fs-5">
                    search
                  </span>
                </div>
              </div>
              <div class="col-auto flex-grow-1 overflow-auto">
                <div class="btn-group position-static">
                  <div class="btn-group position-static">
                    <button
                      type="button"
                      class="btn btn-filter dropdown-toggle px-4"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More Filters
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          className={`dropdown-item ${
                            filterType === "all" ? "active" : ""
                          }`}
                          href="#"
                          onClick={() => handleFilterChange("all")}
                        >
                          All customers
                        </a>
                      </li>
                      <li>
                        <a
                          className={`dropdown-item ${
                            filterType === "active" ? "active" : ""
                          }`}
                          href="#"
                          onClick={() => handleFilterChange("active")}
                        >
                          Active Customers
                        </a>
                      </li>
                      <li>
                        <a
                          className={`dropdown-item ${
                            filterType === "inactive" ? "active" : ""
                          }`}
                          href="#"
                          onClick={() => handleFilterChange("inactive")}
                        >
                          Inactive Customers
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="customer-table mt-3">
              <div className="table-responsive white-space-nowrap">
                <table className="table align-middle">
                  <thead className="table-light">
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      {/* <th>Date of Birth</th> */}
                      <th>Address</th>
                      <th>Status</th>
                      <th>Market Preferences</th>
                    </tr>
                  </thead>
                  {currentItems?.length > 0 || customers === undefined ? (
                    <tbody>
                      {currentItems?.map((customer, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedCustomers.includes(
                                customer.cust_id
                              )}
                              onChange={() =>
                                handleSelectCustomer(customer.cust_id)
                              }
                            />
                          </td>
                          <td>
                            <a
                              className="d-flex align-items-center gap-3"
                              href="#"
                            >
                              {/* <div className="customer-pic">
                              <img
                                src={avatars}
                                className="rounded-circle"
                                width="40"
                                height="40"
                                alt="Customer"
                              />
                            </div> */}
                              <p className="mb-0 customer-name fw-bold">
                                {getName(customer)}
                              </p>
                            </a>
                          </td>
                          <td>
                            <a
                              href={`mailto:${customer.cust_email}`}
                              className="font-text1"
                            >
                              {customer.cust_email}
                            </a>
                          </td>
                          <td>{customer.cust_mobileNumber}</td>
                          {/* <td>{customer.cust_dob}</td> */}
                          <td>
                            {customer.cust_address} {customer.city}{" "}
                            {customer.state} {customer.country}{" "}
                            {customer.zipcode}
                          </td>
                          <td>{customer.cust_status}</td>
                          <td>{customer.cust_market_pref}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : filteredCustomers?.length === 0 ? (
                    <div className="align-items-center p-5">
                      <p>No Records Founds</p>
                    </div>
                  ) : (
                    <div className="align-items-center p-5">
                      <p>Loading...</p>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 p-3">
            <div>
              <label>
                Items per page:{" "}
                <select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                </select>
              </label>
            </div>
            <div>
              <button
                className={`btn btn-secondary me-2 nav-button ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                className={`btn btn-secondary ms-2 nav-button next-button ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
    </Home>
  );
};

export default NewsletterUsers;
