import React from "react";
import Chart from "react-apexcharts";
import avatar01 from "./assets/images/avatars/01.png";
import welcomeback from "./assets/images/gallery/welcome-back-3.png";
import Sidebar from "./Sidebar";
import facebook from "./assets/images/apps/17.png";
import LinkedIn from "./assets/images/apps/18.png";
import Instagram from "./assets/images/apps/19.png";
import Snapchat from "./assets/images/apps/20.png";
import Google from "./assets/images/apps/05.png";
import Altaba from "./assets/images/apps/08.png";
import Spotify from "./assets/images/apps/07.png";
import topproducts1 from "./assets/images/top-products/01.png";
import topproducts2 from "./assets/images/top-products/02.png";
import Home from "./Home";
import { useSelector } from "react-redux";
import logo from "./assets/images/logoNew.png";

const Dashboard = () => {
  const { roleName, signinresponse} = useSelector((data) => data?.collections);

  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
    title: {
      align: "center",
      style: {
        fontSize: "16px",
        color: "#666",
        borderRadius: 10,
      },
    },
  };

  const series = [
    {
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
  ];

  const rediusOptions = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "50%",
        },
        dataLabels: {
          name: {
            // fontSize: "22px",
            display: "none",
          },
          value: {
            fontSize: "16px",
          },
        },
      },
    },

    colors: ["red"],
  };

  const rediusSeries = [75];

  const lineOptions = {
    chart: {
      id: "custom-chart",
      type: "line",
      toolbar: {
        show: false,
      },
      background: "transparent",
      animations: {
        enabled: true,
      },
      height: 400,
    },
    theme: {
      mode: "light",
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar"],
    },
    colors: ["#008FFB", "#FF4560"],
  };

  const lineSeries = [
    {
      name: "Series 1",
      data: [10, 41, 35],
    },
    // {
    //   name: "Series 2",
    //   data: [20, 30, 40],
    // },
  ];

  const pieOptions = {
    chart: {
      type: "pie",
      height: 350,
    },
    labels: ["Category A", "Category B", "Category C"],
    colors: ["#FF4560", "#008FFB", "#00E396"],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "16px", // Font size for labels
        colors: ["#fff"], // Label text color
      },
    },
    legend: {
      position: "bottom", // Position of the legend
      horizontalAlign: "center", // Align legend horizontally
      floating: false, // Set to true to float the legend over the chart
    },
  };

  const pieSeries = [44, 55, 41];

  const Chart_containerOtions = {
    chart: {
      height: 60, // Chart height
      type: "area", // Chart type
      sparkline: {
        enabled: true, // Enable sparkline (mini-chart without axis/legends)
      },
      zoom: {
        enabled: false, // Disable zoom functionality
      },
    },
    stroke: {
      width: 1.5, // Line thickness
      curve: "smooth", // Smooth curve for the line
    },
    fill: {
      type: "gradient", // Enable gradient fill
      gradient: {
        shade: "dark",
        gradientToColors: ["#2af598"], // Gradient ending color
        shadeIntensity: 1,
        type: "vertical", // Vertical gradient
        opacityFrom: 0.5, // Start opacity
        opacityTo: 0.1, // End opacity
        stops: [0, 100, 100, 100], // Gradient stops
      },
    },
    colors: ["#009efd"], // Line color
    tooltip: {
      theme: "dark", // Tooltip theme
      fixed: {
        enabled: false,
      },
      x: {
        show: false, // Hide X-axis in the tooltip
      },
      y: {
        title: {
          formatter: function () {
            return ""; // No title in the tooltip
          },
        },
      },
      marker: {
        show: false, // Hide marker in the tooltip
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ], // X-axis labels
      labels: {
        show: false, // Hide X-axis labels since it's a sparkline
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels on the chart
    },
  };

  const Chart_containerSeries = [
    {
      name: "Orders",
      data: [47, 45, 74, 32, 56, 31, 44, 33, 45, 19], // Data points for the chart
    },
  ];

  const zigZagoptions = {
    chart: {
      id: "zig-zag-chart",
      type: "line", // Use a line chart
      toolbar: {
        show: false, // Hides the toolbar
      },
    },
    stroke: {
      curve: "stepline", // Creates the zig-zag effect
      width: 2, // Set line thickness
    },
    colors: ["#FF4560"], // Line color
    tooltip: {
      enabled: true, // Show tooltip on hover
    },
  };

  const zigZagSeries = [
    {
      name: "Zig-Zag Data",
      data: [10, 40, 25, 50, 30, 60], // Example data
    },
  ];

  const net_selOptions = {
    chart: {
      height: 105, // Chart height
      type: "area", // Chart type
      sparkline: {
        enabled: true, // Enable sparkline mode
      },
      zoom: {
        enabled: false, // Disable zoom functionality
      },
    },
    stroke: {
      width: 3, // Line thickness
      curve: "smooth", // Smooth curve for the line
    },
    fill: {
      type: "gradient", // Gradient fill
      gradient: {
        shade: "dark",
        gradientToColors: ["#0866ff"], // Gradient ending color
        shadeIntensity: 1,
        type: "vertical", // Vertical gradient
        opacityFrom: 0.5, // Starting opacity
        opacityTo: 0.0, // Ending opacity
      },
    },
    colors: ["#02c27a"], // Line color
    tooltip: {
      theme: "dark", // Tooltip theme
      fixed: {
        enabled: false, // Disable fixed tooltip
      },
      x: {
        show: false, // Hide X-axis tooltip
      },
      y: {
        title: {
          formatter: () => "", // Hide Y-axis title in tooltip
        },
      },
      marker: {
        show: false, // Hide tooltip marker
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels on the chart
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ], // X-axis labels
    },
  };

  const net_selSeries = [
    {
      name: "Net Sales", // Series name
      data: [4, 10, 25, 12, 25, 18, 40, 22, 7], // Data points
    },
  ];

  const data = [4, 10, 25, 12, 25, 18, 40, 22, 7]; // Data for the chart
  const maxPointIndex = data.indexOf(Math.max(...data)); // Index of the highest point
  const categories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
  ];

  const visitorGrowthoptions = {
    chart: {
      height: 105,
      type: "area",
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#red"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 0.5,
        opacityTo: 0.0,
      },
    },
    colors: ["#red"],
    tooltip: {
      theme: "dark",
      x: { show: false },
      y: {
        title: {
          formatter: () => "",
        },
      },
      marker: { show: false },
    },
    xaxis: {
      categories,
    },
    annotations: {
      points: [
        {
          x: categories[maxPointIndex], // X-axis label of the highest point
          y: data[maxPointIndex], // Y-axis value of the highest point
          marker: {
            size: 6,
            fillColor: "#ff4560", // Marker color
            strokeColor: "#fff", // Marker border color
            strokeWidth: 2,
          },
          label: {
            text: `Highest: ${data[maxPointIndex]}`, // Annotation label
            borderColor: "#ff4560",
            style: {
              background: "#ff4560",
              color: "#fff",
            },
          },
        },
      ],
    },
  };

  const visitorGrowthseries = [
    {
      name: "Net Sales",
      data,
    },
  ];

  return (
    <Home>
      <Sidebar />
      <main class="main-wrapper">
        <div class="main-content">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div class="breadcrumb-title pe-3">Dashboard</div>
            <div class="ps-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 p-0">
                  <li class="breadcrumb-item">
                    <a href="javascript:;">
                      <i class="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Analysis
                  </li>
                </ol>
              </nav>
            </div>
            <div class="ms-auto">
              <div class="btn-group">
                <button type="button" class="btn btn-outline-primary">
                  Settings
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                >
                  {" "}
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                  {" "}
                  <a class="dropdown-item" href="javascript:;">
                    Action
                  </a>
                  <a class="dropdown-item" href="javascript:;">
                    Another action
                  </a>
                  <a class="dropdown-item" href="javascript:;">
                    Something else here
                  </a>
                  <div class="dropdown-divider"></div>{" "}
                  <a class="dropdown-item" href="javascript:;">
                    Separated link
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xxl-8 d-flex align-items-stretch">
              <div class="card w-100 overflow-hidden rounded-4">
                <div class="card-body position-relative p-4">
                  <div class="row">
                    <div class="col-12 col-sm-7">
                      <div class="d-flex align-items-center gap-3 mb-5">
                        <img
                          src={roleName?.role === "SUPER_ADMIN" ? logo : avatar01}
                          class="rounded-circle bg-grd-info p-1"
                          width="60"
                          height="60"
                          alt="user"
                        />
                        <div class="">
                          <p class="mb-0 fw-semibold">Welcome back</p>
                          {roleName?.role === "SUPER_ADMIN" ? (
                            <h4 class="fw-semibold mb-0 fs-4 mb-0">
                              My Digital Solutions
                            </h4>
                          ) : (
                            <h4 class="fw-semibold mb-0 fs-4 mb-0">
                              Jhon Anderson!
                            </h4>
                          )}
                        </div>
                      </div>
                      <div class="d-flex align-items-center gap-5">
                        <div class="">
                          <h4 class="mb-1 fw-semibold d-flex align-content-center">
                            $65.4K
                            <i class="ti ti-arrow-up-right fs-5 lh-base text-success"></i>
                          </h4>
                          <p class="mb-3">Today's Sales</p>
                          <div class="progress mb-0" style={{ height: "5px" }}>
                            <div
                              class="progress-bar bg-grd-success"
                              role="progressbar"
                              style={{ width: "60%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div class="vr"></div>
                        <div class="">
                          <h4 class="mb-1 fw-semibold d-flex align-content-center">
                            78.4%
                            <i class="ti ti-arrow-up-right fs-5 lh-base text-success"></i>
                          </h4>
                          <p class="mb-3">Growth Rate</p>
                          <div class="progress mb-0" style={{ height: "5px" }}>
                            <div
                              class="progress-bar bg-grd-danger"
                              role="progressbar"
                              style={{ width: "60%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-5">
                      <div class="welcome-back-img pt-4">
                        <img src={welcomeback} height="180" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-xxl-2 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-start justify-content-between mb-1">
                    <div class="">
                      <h5 class="mb-0">42.5K</h5>
                      <p class="mb-0">Active Users</p>
                    </div>
                    <div class="dropdown">
                      <a
                        href="javascript:;"
                        class="dropdown-toggle-nocaret options dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span class="material-icons-outlined fs-5">
                          more_vert
                        </span>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Another action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chart-container2">
                    <div id="chart1">
                      <Chart
                        options={rediusOptions}
                        series={rediusSeries}
                        type="radialBar"
                        height={350}
                      />
                    </div>{" "}
                  </div>
                  <div class="text-center">
                    <p class="mb-0 font-12">
                      24K users increased from last month
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-xxl-2 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-start justify-content-between mb-3">
                    <div class="">
                      <h5 class="mb-0">97.4K</h5>
                      <p class="mb-0">Total Users</p>
                    </div>
                    <div class="dropdown">
                      <a
                        href="javascript:;"
                        class="dropdown-toggle-nocaret options dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span class="material-icons-outlined fs-5">
                          more_vert
                        </span>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Another action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chart-container2">
                    <Chart
                      options={lineOptions}
                      series={lineSeries}
                      type="line"
                      height={150}
                    />
                  </div>
                  <div class="text-center">
                    <p class="mb-0 font-12">
                      <span class="text-success me-1">12.5%</span> from last
                      month
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-body">
                  <div class="text-center">
                    <h6 class="mb-0">Monthly Revenue</h6>
                  </div>
                  <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                  />
                  <p>Avrage monthly sale for every author</p>
                  <div class="d-flex align-items-center gap-3 mt-4">
                    <div class="">
                      <h1 class="mb-0 text-primary">68.9%</h1>
                    </div>
                    <div class="d-flex align-items-center align-self-end">
                      <p class="mb-0 text-success">34.5%</p>
                      <span class="material-icons-outlined text-success">
                        expand_less
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-body">
                  <div class="d-flex flex-column gap-3">
                    <div class="d-flex align-items-start justify-content-between">
                      <div class="">
                        <h5 class="mb-0">Device Type</h5>
                      </div>
                      <div class="dropdown">
                        <a
                          href="javascript:;"
                          class="dropdown-toggle-nocaret options dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          <span class="material-icons-outlined fs-5">
                            more_vert
                          </span>
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <a class="dropdown-item" href="javascript:;">
                              Action
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="javascript:;">
                              Another action
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="javascript:;">
                              Something else here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="position-relative">
                      <div class="piechart-legend">
                        <h2 class="mb-1">68%</h2>
                        <h6 class="mb-0">Total Views</h6>
                      </div>
                      <Chart
                        options={pieOptions}
                        series={pieSeries}
                        type="pie"
                        height={350}
                      />
                    </div>
                    <div class="d-flex flex-column gap-3">
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="mb-0 d-flex align-items-center gap-2 w-25">
                          <span class="material-icons-outlined fs-6 text-primary">
                            desktop_windows
                          </span>
                          Desktop
                        </p>
                        <div class="">
                          <p class="mb-0">35%</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="mb-0 d-flex align-items-center gap-2 w-25">
                          <span class="material-icons-outlined fs-6 text-danger">
                            tablet_mac
                          </span>
                          Tablet
                        </p>
                        <div class="">
                          <p class="mb-0">48%</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="mb-0 d-flex align-items-center gap-2 w-25">
                          <span class="material-icons-outlined fs-6 text-success">
                            phone_android
                          </span>
                          Mobile
                        </p>
                        <div class="">
                          <p class="mb-0">27%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-4">
              <div class="row">
                <div class="col-md-6 d-flex align-items-stretch">
                  <div class="card w-100 rounded-4">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between mb-1">
                        <div class="">
                          <h5 class="mb-0">82.7K</h5>
                          <p class="mb-0">Total Clicks</p>
                        </div>
                        <div class="dropdown">
                          <a
                            href="javascript:;"
                            class="dropdown-toggle-nocaret options dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <span class="material-icons-outlined fs-5">
                              more_vert
                            </span>
                          </a>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="javascript:;">
                                Action
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="javascript:;">
                                Another action
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="javascript:;">
                                Something else here
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="chart-container2">
                        <div className="apexcharts-custom-container">
                          <Chart
                            options={Chart_containerOtions}
                            series={Chart_containerSeries}
                            type="area"
                            height={140}
                          />
                        </div>{" "}
                      </div>
                      <div class="text-center">
                        <p class="mb-0 font-12">
                          <span class="text-success me-1">12.5%</span> from last
                          month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 d-flex align-items-stretch">
                  <div class="card w-100 rounded-4">
                    <div class="card-body">
                      <div class="d-flex align-items-start justify-content-between mb-1">
                        <div class="">
                          <h5 class="mb-0">68.4K</h5>
                          <p class="mb-0">Total Views</p>
                        </div>
                        <div class="dropdown">
                          <a
                            href="javascript:;"
                            class="dropdown-toggle-nocaret options dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <span class="material-icons-outlined fs-5">
                              more_vert
                            </span>
                          </a>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="javascript:;">
                                Action
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="javascript:;">
                                Another action
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="javascript:;">
                                Something else here
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="chart-container2">
                        <Chart
                          options={zigZagoptions}
                          series={zigZagSeries}
                          type="line"
                          height={150}
                        />
                      </div>
                      <div class="text-center">
                        <p class="mb-0 font-12">
                          35K users increased from last month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-center gap-3 mb-2">
                    <div class="">
                      <h3 class="mb-0">85,247</h3>
                    </div>
                    <div class="flex-grow-0">
                      <p class="dash-lable d-flex align-items-center gap-1 rounded mb-0 bg-success text-success bg-opacity-10">
                        <span class="material-icons-outlined fs-6">
                          arrow_downward
                        </span>
                        23.7%
                      </p>
                    </div>
                  </div>
                  <p class="mb-0">Total Accounts</p>
                  <Chart
                    options={net_selOptions}
                    series={net_selSeries}
                    type="area"
                    height={105}
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-start justify-content-between mb-3">
                    <div class="">
                      <h6 class="mb-0 fw-bold">Campaign Stats</h6>
                    </div>
                    <div class="dropdown">
                      <a
                        href="javascript:;"
                        class="dropdown-toggle-nocaret options dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span class="material-icons-outlined fs-5">
                          more_vert
                        </span>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Another action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <ul class="list-group list-group-flush">
                    <li class="list-group-item px-0 bg-transparent">
                      <div class="d-flex align-items-center gap-3">
                        <div class="wh-42 d-flex align-items-center justify-content-center rounded-3 bg-grd-primary">
                          <span class="material-icons-outlined text-white">
                            calendar_today
                          </span>
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Campaigns</h6>
                        </div>
                        <div class="d-flex align-items-center gap-3">
                          <p class="mb-0">54</p>
                          <p class="mb-0 fw-bold text-success">28%</p>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item px-0 bg-transparent">
                      <div class="d-flex align-items-center gap-3">
                        <div class="wh-42 d-flex align-items-center justify-content-center rounded-3 bg-grd-success">
                          <span class="material-icons-outlined text-white">
                            email
                          </span>
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Emailed</h6>
                        </div>
                        <div class="d-flex align-items-center gap-3">
                          <p class="mb-0">245</p>
                          <p class="mb-0 fw-bold text-danger">15%</p>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item px-0 bg-transparent">
                      <div class="d-flex align-items-center gap-3">
                        <div class="wh-42 d-flex align-items-center justify-content-center rounded-3 bg-grd-branding">
                          <span class="material-icons-outlined text-white">
                            open_in_new
                          </span>
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Opened</h6>
                        </div>
                        <div class="d-flex align-items-center gap-3">
                          <p class="mb-0">54</p>
                          <p class="mb-0 fw-bold text-success">30.5%</p>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item px-0 bg-transparent">
                      <div class="d-flex align-items-center gap-3">
                        <div class="wh-42 d-flex align-items-center justify-content-center rounded-3 bg-grd-warning">
                          <span class="material-icons-outlined text-white">
                            ads_click
                          </span>
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Clicked</h6>
                        </div>
                        <div class="d-flex align-items-center gap-3">
                          <p class="mb-0">859</p>
                          <p class="mb-0 fw-bold text-danger">34.6%</p>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item px-0 bg-transparent">
                      <div class="d-flex align-items-center gap-3">
                        <div class="wh-42 d-flex align-items-center justify-content-center rounded-3 bg-grd-info">
                          <span class="material-icons-outlined text-white">
                            subscriptions
                          </span>
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Subscribed</h6>
                        </div>
                        <div class="d-flex align-items-center gap-3">
                          <p class="mb-0">24,758</p>
                          <p class="mb-0 fw-bold text-success">53%</p>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item px-0 bg-transparent">
                      <div class="d-flex align-items-center gap-3">
                        <div class="wh-42 d-flex align-items-center justify-content-center rounded-3 bg-grd-danger">
                          <span class="material-icons-outlined text-white">
                            inbox
                          </span>
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Spam Message</h6>
                        </div>
                        <div class="d-flex align-items-center gap-3">
                          <p class="mb-0">548</p>
                          <p class="mb-0 fw-bold text-danger">47%</p>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item px-0 bg-transparent">
                      <div class="d-flex align-items-center gap-3">
                        <div class="wh-42 d-flex align-items-center justify-content-center rounded-3 bg-grd-deep-blue">
                          <span class="material-icons-outlined text-white">
                            visibility
                          </span>
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Views Mails</h6>
                        </div>
                        <div class="d-flex align-items-center gap-3">
                          <p class="mb-0">9845</p>
                          <p class="mb-0 fw-bold text-success">68%</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-body">
                  <Chart
                    options={visitorGrowthoptions}
                    series={visitorGrowthseries}
                    type="area"
                    height={105}
                  />

                  <div class="d-flex align-items-center gap-3 mt-4">
                    <div class="">
                      <h1 class="mb-0">36.7%</h1>
                    </div>
                    <div class="d-flex align-items-center align-self-end gap-2">
                      <span class="material-icons-outlined text-success">
                        trending_up
                      </span>
                      <p class="mb-0 text-success">34.5%</p>
                    </div>
                  </div>
                  <p class="mb-4">Visitors Growth</p>
                  <div class="d-flex flex-column gap-3">
                    <div class="">
                      <p class="mb-1">
                        Cliks <span class="float-end">2589</span>
                      </p>
                      <div class="progress" style={{ height: "5px" }}>
                        <div
                          class="progress-bar bg-grd-primary"
                          style={{ width: "65%" }}
                        ></div>
                      </div>
                    </div>
                    <div class="">
                      <p class="mb-1">
                        Likes <span class="float-end">6748</span>
                      </p>
                      <div class="progress" style={{ height: "5px" }}>
                        <div
                          class="progress-bar bg-grd-warning"
                          style={{ width: "55%" }}
                        ></div>
                      </div>
                    </div>
                    <div class="">
                      <p class="mb-1">
                        Upvotes <span class="float-end">9842</span>
                      </p>
                      <div class="progress" style={{ height: "5px" }}>
                        <div
                          class="progress-bar bg-grd-info"
                          style={{ width: "45%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-start justify-content-between mb-3">
                    <div class="">
                      <h5 class="mb-0 fw-bold">Social Leads</h5>
                    </div>
                    <div class="dropdown">
                      <a
                        href="javascript:;"
                        class="dropdown-toggle-nocaret options dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span class="material-icons-outlined fs-5">
                          more_vert
                        </span>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Another action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-between gap-4">
                    <div class="d-flex align-items-center gap-4">
                      <div class="d-flex align-items-center gap-3 flex-grow-1">
                        <img src={facebook} width="32" alt="" />
                        <p class="mb-0">Facebook</p>
                      </div>
                      <div class="">
                        <p class="mb-0 fs-6">55%</p>
                      </div>
                      <div class="">
                        <p class="mb-0 data-attributes">
                          <span data-peity='{ "fill": ["#0d6efd", "rgb(255 255 255 / 10%)"], "innerRadius": 14, "radius": 18 }'>
                            5/7
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center gap-4">
                      <div class="d-flex align-items-center gap-3 flex-grow-1">
                        <img src={LinkedIn} width="32" alt="" />
                        <p class="mb-0">LinkedIn</p>
                      </div>
                      <div class="">
                        <p class="mb-0 fs-6">67%</p>
                      </div>
                      <div class="">
                        <p class="mb-0 data-attributes">
                          <span data-peity='{ "fill": ["#fc185a", "rgb(255 255 255 / 10%)"], "innerRadius": 14, "radius": 18 }'>
                            5/7
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center gap-4">
                      <div class="d-flex align-items-center gap-3 flex-grow-1">
                        <img src={Instagram} width="32" alt="" />
                        <p class="mb-0">Instagram</p>
                      </div>
                      <div class="">
                        <p class="mb-0 fs-6">78%</p>
                      </div>
                      <div class="">
                        <p class="mb-0 data-attributes">
                          <span data-peity='{ "fill": ["#02c27a", "rgb(255 255 255 / 10%)"], "innerRadius": 14, "radius": 18 }'>
                            5/7
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center gap-4">
                      <div class="d-flex align-items-center gap-3 flex-grow-1">
                        <img src={Snapchat} width="32" alt="" />
                        <p class="mb-0">Snapchat</p>
                      </div>
                      <div class="">
                        <p class="mb-0 fs-6">46%</p>
                      </div>
                      <div class="">
                        <p class="mb-0 data-attributes">
                          <span data-peity='{ "fill": ["#fd7e14", "rgb(255 255 255 / 10%)"], "innerRadius": 14, "radius": 18 }'>
                            5/7
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center gap-4">
                      <div class="d-flex align-items-center gap-3 flex-grow-1">
                        <img src={Google} width="32" alt="" />
                        <p class="mb-0">Google</p>
                      </div>
                      <div class="">
                        <p class="mb-0 fs-6">38%</p>
                      </div>
                      <div class="">
                        <p class="mb-0 data-attributes">
                          <span data-peity='{ "fill": ["#0dcaf0", "rgb(255 255 255 / 10%)"], "innerRadius": 14, "radius": 18 }'>
                            5/7
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center gap-4">
                      <div class="d-flex align-items-center gap-3 flex-grow-1">
                        <img src={Altaba} width="32" alt="" />
                        <p class="mb-0">Altaba</p>
                      </div>
                      <div class="">
                        <p class="mb-0 fs-6">15%</p>
                      </div>
                      <div class="">
                        <p class="mb-0 data-attributes">
                          <span data-peity='{ "fill": ["#6f42c1", "rgb(255 255 255 / 10%)"], "innerRadius": 14, "radius": 18 }'>
                            5/7
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center gap-4">
                      <div class="d-flex align-items-center gap-3 flex-grow-1">
                        <img src={Spotify} width="32" alt="" />
                        <p class="mb-0">Spotify</p>
                      </div>
                      <div class="">
                        <p class="mb-0 fs-6">12%</p>
                      </div>
                      <div class="">
                        <p class="mb-0 data-attributes">
                          <span data-peity='{ "fill": ["#ff00b3", "rgb(255 255 255 / 10%)"], "innerRadius": 14, "radius": 18 }'>
                            5/7
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-xxl-4 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-header border-0 p-3 border-bottom">
                  <div class="d-flex align-items-start justify-content-between">
                    <div class="">
                      <h5 class="mb-0">New Users</h5>
                    </div>
                    <div class="dropdown">
                      <a
                        href="javascript:;"
                        class="dropdown-toggle-nocaret options dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span class="material-icons-outlined fs-5">
                          more_vert
                        </span>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Another action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <div class="user-list p-3">
                    <div class="d-flex flex-column gap-3">
                      <div class="d-flex align-items-center gap-3">
                        <img
                          src={avatar01}
                          width="45"
                          height="45"
                          class="rounded-circle"
                          alt=""
                        />
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Elon Jonado</h6>
                          <p class="mb-0">elon_deo</p>
                        </div>
                        <div class="form-check form-check-inline me-0">
                          <input
                            class="form-check-input ms-0"
                            type="checkbox"
                          />
                        </div>
                      </div>
                      <div class="d-flex align-items-center gap-3">
                        <img
                          src={avatar01}
                          width="45"
                          height="45"
                          class="rounded-circle"
                          alt=""
                        />
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Alexzender Clito</h6>
                          <p class="mb-0">zli_alexzender</p>
                        </div>
                        <div class="form-check form-check-inline me-0">
                          <input
                            class="form-check-input ms-0"
                            type="checkbox"
                          />
                        </div>
                      </div>
                      <div class="d-flex align-items-center gap-3">
                        <img
                          src={avatar01}
                          width="45"
                          height="45"
                          class="rounded-circle"
                          alt=""
                        />
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Michle Tinko</h6>
                          <p class="mb-0">tinko_michle</p>
                        </div>
                        <div class="form-check form-check-inline me-0">
                          <input
                            class="form-check-input ms-0"
                            type="checkbox"
                          />
                        </div>
                      </div>
                      <div class="d-flex align-items-center gap-3">
                        <img
                          src={avatar01}
                          width="45"
                          height="45"
                          class="rounded-circle"
                          alt=""
                        />
                        <div class="flex-grow-1">
                          <h6 class="mb-0">KailWemba</h6>
                          <p class="mb-0">wemba_kl</p>
                        </div>
                        <div class="form-check form-check-inline me-0">
                          <input
                            class="form-check-input ms-0"
                            type="checkbox"
                          />
                        </div>
                      </div>
                      <div class="d-flex align-items-center gap-3">
                        <img
                          src={avatar01}
                          width="45"
                          height="45"
                          class="rounded-circle"
                          alt=""
                        />
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Henhco Tino</h6>
                          <p class="mb-0">Henhco_tino</p>
                        </div>
                        <div class="form-check form-check-inline me-0">
                          <input
                            class="form-check-input ms-0"
                            type="checkbox"
                          />
                        </div>
                      </div>
                      <div class="d-flex align-items-center gap-3">
                        <img
                          src={avatar01}
                          width="45"
                          height="45"
                          class="rounded-circle"
                          alt=""
                        />
                        <div class="flex-grow-1">
                          <h6 class="mb-0">Gonjiko Fernando</h6>
                          <p class="mb-0">gonjiko_fernando</p>
                        </div>
                        <div class="form-check form-check-inline me-0">
                          <input
                            class="form-check-input ms-0"
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer bg-transparent p-3">
                  <div class="d-flex align-items-center justify-content-between gap-3">
                    <a href="javascript:;" class="sharelink">
                      <i class="material-icons-outlined">share</i>
                    </a>
                    <a href="javascript:;" class="sharelink">
                      <i class="material-icons-outlined">textsms</i>
                    </a>
                    <a href="javascript:;" class="sharelink">
                      <i class="material-icons-outlined">email</i>
                    </a>
                    <a href="javascript:;" class="sharelink">
                      <i class="material-icons-outlined">attach_file</i>
                    </a>
                    <a href="javascript:;" class="sharelink">
                      <i class="material-icons-outlined">event</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xxl-8 d-flex align-items-stretch">
              <div class="card w-100 rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-start justify-content-between mb-3">
                    <div class="">
                      <h5 class="mb-0">Recent Orders</h5>
                    </div>
                    <div class="dropdown">
                      <a
                        href="javascript:;"
                        class="dropdown-toggle-nocaret options dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span class="material-icons-outlined fs-5">
                          more_vert
                        </span>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Another action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="javascript:;">
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="order-search position-relative my-3">
                    <input
                      class="form-control rounded-5 px-5"
                      type="text"
                      placeholder="Search"
                    />
                    <span class="material-icons-outlined position-absolute ms-3 translate-middle-y start-0 top-50">
                      search
                    </span>
                  </div>
                  <div class="table-responsive">
                    <table class="table align-middle">
                      <thead>
                        <tr>
                          <th>Item Name</th>
                          <th>Amount</th>
                          <th>Vendor</th>
                          <th>Status</th>
                          <th>Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center gap-3">
                              <div class="">
                                <img
                                  src={topproducts1}
                                  class="rounded-circle"
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <p class="mb-0">Sports Shoes</p>
                            </div>
                          </td>
                          <td>$149</td>
                          <td>Julia Sunota</td>
                          <td>
                            <p class="dash-lable mb-0 bg-success bg-opacity-10 text-success rounded-2">
                              Completed
                            </p>
                          </td>
                          <td>
                            <div class="d-flex align-items-center gap-1">
                              <p class="mb-0">5.0</p>
                              <i class="material-icons-outlined text-warning fs-6">
                                star
                              </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center gap-3">
                              <div class="">
                                <img
                                  src={topproducts2}
                                  class="rounded-circle"
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <p class="mb-0">Goldan Watch</p>
                            </div>
                          </td>
                          <td>$168</td>
                          <td>Julia Sunota</td>
                          <td>
                            <p class="dash-lable mb-0 bg-success bg-opacity-10 text-success rounded-2">
                              Completed
                            </p>
                          </td>
                          <td>
                            <div class="d-flex align-items-center gap-1">
                              <p class="mb-0">5.0</p>
                              <i class="material-icons-outlined text-warning fs-6">
                                star
                              </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center gap-3">
                              <div class="">
                                <img
                                  src={topproducts2}
                                  class="rounded-circle"
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <p class="mb-0">Men Polo Tshirt</p>
                            </div>
                          </td>
                          <td>$124</td>
                          <td>Julia Sunota</td>
                          <td>
                            <p class="dash-lable mb-0 bg-warning bg-opacity-10 text-warning rounded-2">
                              Pending
                            </p>
                          </td>
                          <td>
                            <div class="d-flex align-items-center gap-1">
                              <p class="mb-0">4.0</p>
                              <i class="material-icons-outlined text-warning fs-6">
                                star
                              </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center gap-3">
                              <div class="">
                                <img
                                  src={topproducts1}
                                  class="rounded-circle"
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <p class="mb-0">Blue Jeans Casual</p>
                            </div>
                          </td>
                          <td>$289</td>
                          <td>Julia Sunota</td>
                          <td>
                            <p class="dash-lable mb-0 bg-success bg-opacity-10 text-success rounded-2">
                              Completed
                            </p>
                          </td>
                          <td>
                            <div class="d-flex align-items-center gap-1">
                              <p class="mb-0">3.0</p>
                              <i class="material-icons-outlined text-warning fs-6">
                                star
                              </i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center gap-3">
                              <div class="">
                                <img
                                  src={topproducts1}
                                  class="rounded-circle"
                                  width="50"
                                  height="50"
                                  alt=""
                                />
                              </div>
                              <p class="mb-0">Fancy Shirts</p>
                            </div>
                          </td>
                          <td>$389</td>
                          <td>Julia Sunota</td>
                          <td>
                            <p class="dash-lable mb-0 bg-danger bg-opacity-10 text-danger rounded-2">
                              Canceled
                            </p>
                          </td>
                          <td>
                            <div class="d-flex align-items-center gap-1">
                              <p class="mb-0">2.0</p>
                              <i class="material-icons-outlined text-warning fs-6">
                                star
                              </i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Home>
  );
};
export default Dashboard;
