import {
  ADDCUSTOMERSFORM,
  OPENTHEMESETTING,
  LOGIN_SUCCESS,
  CUSTOMERDETAILSPAGE,
  GET_CUSTOMERS_DATA,
  RESPONSE,
  ADDCONTRACTDATA,
  ADDEMPLOYEEFORM,
  GET_EMPLOYEE_DATA,
  SUBSCRIBDATA,
  SELECTEDDATA,
  BIRTHDATA,
  ROLEDATA,
  ADDCOMPANYFORM,
  GET_COMPANY_DATA,
  GET_COMPANY_LOAN,
  ADDLOANFORM,
  LOAN_PRODUCTS,
  LOANSDATA,
  ADDSECONDRYDATA,
  LOANDETAILSPAGE,
  EMPDETAILSPAGE,
} from "../actions/Type";

const initialState = {
  isAuth: localStorage.getItem("access_token") ? true : false,
};

const collections = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        signinresponse: action.payload,
        loading: true,
        isAuth: true,
      };

    case OPENTHEMESETTING:
      return { ...state, openThemeSettingModal: action.payload };
    case ADDCUSTOMERSFORM:
      return { ...state, addCustomers: action.payload };
    case ADDCOMPANYFORM:
      return { ...state, openCompanyForm: action.payload };
    case ADDCONTRACTDATA:
      return { ...state, addContract: action.payload, loading: false };
    case ADDEMPLOYEEFORM:
      return { ...state, addEmployee: action.payload };
    case CUSTOMERDETAILSPAGE:
      return { ...state, openCustomersPage: action.payload };
    case GET_CUSTOMERS_DATA:
      return { ...state, getAllCustomer: action.payload, loading: false };
    case GET_COMPANY_DATA:
      return { ...state, getAllCompany: action.payload, loading: false };
    case RESPONSE:
      return { ...state, response: action.payload, loading: false };
    case GET_EMPLOYEE_DATA:
      return { ...state, getAllEmployee: action.payload, loading: false };
    case SUBSCRIBDATA:
      return { ...state, subscribResponseData: action.payload };
    case SELECTEDDATA:
      return { ...state, allSelectedData: action.payload };
    case ROLEDATA:
      return { ...state, roleName: action.payload };
    case GET_COMPANY_LOAN:
      return { ...state, allLoanData: action.payload };
    case ADDLOANFORM:
      return { ...state, openLoanAdd: action.payload };
    case LOAN_PRODUCTS:
      return { ...state, LoanProductsData: action.payload };
    case LOANSDATA:
      return { ...state, LoanData: action.payload };
    case BIRTHDATA:
      return { ...state, birthDayData: action.payload };
    case LOANDETAILSPAGE:
      return { ...state, openLoanDetails: action.payload };
    case EMPDETAILSPAGE:
      return { ...state, openEmpDetailsData: action.payload };

    case ADDSECONDRYDATA:
      return { ...state, secondryCustData: action.payload };
    default:
      return state;
  }
};

export default collections;
