import {
  OPENTHEMESETTING,
  ADDCUSTOMERSFORM,
  CUSTOMERDETAILSPAGE,
  RESPONSE_FAILURE,
  RESPONSE,
  ADDCONTRACTDATA,
  ADDEMPLOYEEFORM,
  SUBSCRIBDATA,
  LOGIN_SUCCESS,
  SELECTEDDATA,
  BIRTHDATA,
  ROLEDATA,
  ADDCOMPANYFORM,
  ADDLOANFORM,
  ADDSECONDRYDATA,
  LOANSDATA,
  LOANDETAILSPAGE,
  EMPDETAILSPAGE,
} from "./Type";

export const openThemeSetting = (data) => async (dispatch) => {
  dispatch({ type: OPENTHEMESETTING, payload: data });
};

export const addCustomersForm = (data) => async (dispatch) => {
  dispatch({ type: ADDCUSTOMERSFORM, payload: data });
};
export const addCompanyForm = (data) => async (dispatch) => {
  dispatch({ type: ADDCOMPANYFORM, payload: data });
};

export const addEmployeeForm = (data) => async (dispatch) => {
  dispatch({ type: ADDEMPLOYEEFORM, payload: data });
};

export const addLoanForm = (data) => async (dispatch) => {
  dispatch({ type: ADDLOANFORM, payload: data });
};

export const addContractOpen = (data) => async (dispatch) => {
  dispatch({ type: ADDCONTRACTDATA, payload: data });
};

export const openCustomerDetails = (data) => async (dispatch) => {
  dispatch({ type: CUSTOMERDETAILSPAGE, payload: data });
};

export const openEmpDetails = (data) => async (dispatch) => {
  dispatch({ type: EMPDETAILSPAGE, payload: data });
};

export const openLoanDetailsPage = (data) => async (dispatch) => {
  dispatch({ type: LOANDETAILSPAGE, payload: data });
};

export const responseFailure = (data) => async (dispatch) => {
  if (data?.response?.data?.message) {
    dispatch({
      type: RESPONSE_FAILURE,
      payload: data?.response?.data?.message,
    });
  } else {
    dispatch({ type: RESPONSE_FAILURE, payload: data?.message });
  }
};

export const resetResponse = () => async (dispatch) => {
  dispatch({ type: RESPONSE, payload: "" });
};

export const resetResponseLoan = () => async (dispatch) => {
  dispatch({ type: LOANSDATA, payload: "" });
};

export const resetResponseLogin = () => async (dispatch) => {
  dispatch({ type: LOGIN_SUCCESS, payload: "" });
};

export const resetSubscription = () => async (dispatch) => {
  dispatch({ type: SUBSCRIBDATA, payload: "" });
};

export const selectedData = (data) => async (dispatch) => {
  dispatch({ type: SELECTEDDATA, payload: data });
};

export const birthDateNotifi = (data) => async (dispatch) => {
  dispatch({ type: BIRTHDATA, payload: data });
};

export const roleData = (data) => async (dispatch) => {
  dispatch({ type: ROLEDATA, payload: data });
};

export const addSecondryCustomer = (data) => async (dispatch) => {
  dispatch({ type: ADDSECONDRYDATA, payload: data });
};

