import React, { useState, useEffect } from "react";
import logo1 from "./assets/images/logoNew.png";
import login from "./assets/images/auth/login1.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { userSignin } from "./redux/actions/Api";

const Signin = () => {
  const dispatch = useDispatch();
  const { signinresponse } = useSelector((data) => data?.collections);
  
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [password, setPassword] = useState(localStorage.getItem("password"));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(localStorage.getItem("rememberMe") === "true");

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", "blue-theme");
  }, []);

  useEffect(() => {
    if (signinresponse) {
      setMessage(signinresponse?.message);
      if (signinresponse?.message) {
        toast.success(signinresponse.message);
        window.location.href = "/dashboard";
      }
    }
  }, [signinresponse]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (rememberMe) {
      localStorage.setItem("email", email);
      localStorage.setItem("rememberMe", true);
    } else {
      localStorage.removeItem("email");
      localStorage.setItem("rememberMe", false);
    }

    const credentials = { email, password };
    dispatch(userSignin(credentials));

    // if(credentials){
    //   localStorage.setItem("credentials", email);
    //   window.location.href = "/VerifyOtp";
    // }else {
    //   toast.error("Enter a valid credentials");
    // }
  };

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleChecked = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <div className="section-authentication-cover">
      <div className="">
        <div className="row g-0">
          <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex border-end bg-transparent">
            <div className="card rounded-0 mb-0 border-0 shadow-none bg-transparent bg-none">
              <div className="card-body">
                <img src={login} className="img-fluid auth-img-cover-login" width="650" alt="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center border-top border-4 border-primary border-gradient-1">
            <div className="card rounded-0 m-3 mb-0 border-0 shadow-none bg-none">
              <div className="card-body p-sm-5">
                <img src={logo1} className="mb-4" width="70" alt="" />
                <span style={{ fontSize: "35px", fontWeight: "bold" }}>MDS</span>
                <h4 className="fw-bold">Get Started Now</h4>
                <p className="mb-0">Enter your credentials to login your account</p>

                {message && (
                  <div className="alert alert-info mt-2" role="alert">
                    {message}
                  </div>
                )}

                <div className="separator section-padding pt-3">
                  <div className="line"></div>
                  <p className="mb-0 fw-bold ">OR</p>
                  <div className="line"></div>
                </div>

                <div className="form-body mt-4">
                  <form onSubmit={handleSubmit} className="row g-3">
                    <div className="col-12">
                      <label htmlFor="inputEmailAddress" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmailAddress"
                        placeholder="Enter Email or UserName"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-12">
                      <label htmlFor="inputChoosePassword" className="form-label">
                        Password
                      </label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          id="inputChoosePassword"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter Password"
                          required
                        />
                        <button type="button" className="input-group-text bg-transparent border-0" onClick={handleTogglePassword}>
                          <i className={`bi ${showPassword ? "bi-eye-fill" : "bi-eye-slash-fill"}`}></i>
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="rememberMe"
                          checked={rememberMe}
                          onChange={handleChecked}
                        />
                        <label className="form-check-label" htmlFor="rememberMe">
                          Remember Me
                        </label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-grid">
                        <button type="submit" className="btn btn-grd-primary" disabled={loading}>
                          {loading ? "Please Wait..." : "Login"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
