import React from "react";
import Home from "../../Home";

const Help = () => {
  return (
    <Home>
      <main class="main-wrapper">
        <div class="main-content">
          <p> Help</p>
        </div>
      </main>
    </Home>
  );
};
export default Help;
