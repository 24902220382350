import React, { useEffect, useState } from "react";
import logo from "./assets/images/logoNew.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { openThemeSetting, roleData } from "./redux/actions";
import jwtDecode from "jwt-decode";

const Sidebar = () => {
  const location = useLocation();
  const { roleName } = useSelector((data) => data?.collections);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNews, setIsOpenNews] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const toggleDropdownNews = () => {
    setIsOpenNews((prevState) => !prevState);
  };

  const handleSignOut = () => {
    localStorage.removeItem("access_token");
    navigate("/signin");
  };

  const isLoggedIn = localStorage.getItem("access_token");

  useEffect(() => {
    if (isLoggedIn) {
      const decodedData = jwtDecode(isLoggedIn);
      dispatch(roleData(decodedData));
    }
  }, [isLoggedIn]);

  const handleTheme = () => {
    dispatch(openThemeSetting(true));
  };


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    
  };


  return (
    <>
      <Header toggleSidebar={toggleSidebar}/>
      <aside className={`sidebar-wrapper ${isSidebarOpen ? "open" : "collapsed"}`} data-simplebar="true"  >
        <div className="sidebar-header">
          <div className="logo-icon">
            <img src={logo} className="logo-img" alt="Logo" />
          </div>
          <div className="logo-name flex-grow-1">
            <h5 className="mb-0">MDS</h5>
          </div>
        </div>
        <div className="sidebar-nav">
          <ul className="metismenu" id="sidenav">
            <li
              className={`nav-item ${
                location.pathname === "/dashboard" ? "bg-primary" : ""
              }`}
            >
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive ? "text-white" : "nav-link"
                }
              >
                <div className="parent-icon">
                  <span className="material-icons-outlined">home</span>
                </div>
                <div className="menu-title">Dashboard</div>
              </NavLink>
            </li>

            {roleName?.role === "SUPER_ADMIN" && (
             
              <li
                className={`nav-item ${
                  location.pathname === "/company" ? "bg-primary" : ""
                }`}
              >
                <NavLink
                  to="/company"
                  className={({ isActive }) =>
                    isActive ? "text-white" : "nav-link"
                  }
                >
                  <div className="parent-icon">
                    <span className="material-icons-outlined">business</span>
                  </div>
                  <div className="menu-title">Company</div>
                </NavLink>
              </li>
              
              
            )}
            {roleName?.role === "ADMIN" && (
              <>
                <li
                  className={`nav-item ${
                    location.pathname === "/employee" ? "bg-primary" : ""
                  }`}
                >
                  <NavLink
                    to="/employee"
                    className={({ isActive }) =>
                      isActive ? "text-white" : "nav-link"
                    }
                  >
                    <div className="parent-icon">
                      <span className="material-icons-outlined">people</span>
                    </div>
                    <div className="menu-title">Employees</div>
                  </NavLink>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/customers" ? "bg-primary" : ""
                  }`}
                >
                  <NavLink
                    to="/customers"
                    className={({ isActive }) =>
                      isActive ? "text-white" : "nav-link"
                    }
                  >
                    <div className="parent-icon">
                      <span className="material-icons-outlined">group</span>
                    </div>
                    <div className="menu-title">Customers</div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/loan-details"
                    className={({ isActive }) =>
                      `nav-link ${isActive ? "bg-primary text-white" : ""}`
                    }
                  >
                    <div className="parent-icon">
                      <span className="material-icons-outlined">
                        request_page
                      </span>
                    </div>
                    <div className="menu-title">Loans</div>
                  </NavLink>
                </li>
                <li>
                  <a
                    className="has-arrow"
                    aria-expanded={isOpenNews ? true : false}
                    onClick={toggleDropdownNews}
                  >
                    <div className="parent-icon">
                      <i className="material-icons-outlined">campaign</i>
                    </div>
                    <div className="menu-title">Marketing</div>
                  </a>
                  {isOpenNews && (
                    <ul>
                      <li>
                        <NavLink
                          to="/marketing/newsletter"
                          className={({ isActive }) =>
                            `nav-link ${
                              isActive ? "text-white bg-primary" : ""
                            }`
                          }
                        >
                          <div className="parent-icon">
                            <i class="material-icons-outlined">arrow_right</i>
                            <span className="material-icons-outlined">
                              mark_email_read
                            </span>
                          </div>
                          <div className="menu-title">Newsletter</div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/marketing/sms"
                          className={({ isActive }) =>
                            `nav-link ${
                              isActive ? "text-white bg-primary" : ""
                            }`
                          }
                        >
                          <div className="parent-icon">
                            <i class="material-icons-outlined">arrow_right</i>
                            <span className="material-icons-outlined">sms</span>
                          </div>
                          <div className="menu-title">SMS</div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/marketing/birthdays"
                          className={({ isActive }) =>
                            `nav-link ${
                              isActive ? "text-white bg-primary" : ""
                            }`
                          }
                        >
                          <div className="parent-icon">
                            <i class="material-icons-outlined">arrow_right</i>
                            <span className="material-icons-outlined">
                              cake
                            </span>
                          </div>
                          <div className="menu-title">Birthdays</div>
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/reports"
                    className={({ isActive }) =>
                      `nav-link ${isActive ? "text-white bg-primary" : ""}`
                    }
                  >
                    <div className="parent-icon">
                      <span className="material-icons-outlined">
                        assessment
                      </span>
                    </div>
                    <div className="menu-title">Reports</div>
                  </NavLink>
                </li>
              </>
            )}

            <li
              className={`nav-item ${
                location.pathname === "/help" ? "bg-primary" : ""
              }`}
            >
              <NavLink
                to="/help"
                className={({ isActive }) =>
                  isActive ? "text-white" : "nav-link"
                }
              >
                <div className="parent-icon">
                  <span className="material-icons-outlined">help_outline</span>
                </div>
                <div className="menu-title">Help</div>
              </NavLink>
            </li>
            <li>
              <a
                href="#"
                className="has-arrow "
                onClick={toggleDropdown}
                aria-expanded={isOpen ? true : false}
              >
                <div className="parent-icon">
                  <i className="material-icons-outlined">settings</i>
                </div>
                <div className="menu-title">Settings</div>
              </a>
              {isOpen && (
                <ul>
                  <li
                    className="nav-link"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#staticBackdrop"
                    onClick={handleTheme}
                  >
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link" : "nav-link"
                      }
                    >
                      <div className="parent-icon">
                        <i class="material-icons-outlined">arrow_right</i>
                        <span className="material-icons-outlined">tune</span>
                      </div>
                      <div className="menu-title">Theme Settings</div>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            {isLoggedIn && (
              <li
                className={`nav-item ${
                  location.pathname === "/signout" ? "bg-primary" : ""
                }`}
              >
                <a href="#" className="nav-link" onClick={handleSignOut}>
                  <div className="parent-icon">
                    <span className="material-icons-outlined">logout</span>
                  </div>
                  <div className="menu-title">Sign Out</div>
                </a>
              </li>
            )}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
