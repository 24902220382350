import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { addCustomersForm, addSecondryCustomer } from "../../redux/actions";
import {
  addCustomersData,
  addSecondryCustData,
  addSecondryExistingData,
} from "../../redux/actions/Api";
import { MSG } from "../../utils/messages";
import { EMAIL_REGEX, NAME_REGEX, NUM_REGEX } from "../../utils/Regex";
import { useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import { getName } from "../../utils/helper";

const AddSecondaryCustomer = () => {
  const dispatch = useDispatch();
  const { getAllCustomer, secondryCustData, allLoanData } = useSelector(
    (data) => data?.collections
  );
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({ mode: "onTouched" });

  const [show, setShow] = useState(true);
  const [marketPreferences, setMarketPreferences] = useState(false);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const handleClose = () => {
    setShow(false);
    dispatch(addSecondryCustomer(""));
  };

  const toggleMarketPreferences = () => {
    setMarketPreferences(!marketPreferences);
  };

  const onSubmit = (data) => {
    // const isEmailExists = getAllCustomer?.data?.some(
    //   (customer) => customer.email.toLowerCase() === data.email.toLowerCase()
    // );
    // if (isEmailExists) {
    //   setError("email", { type: "manual", message: "Email already exists!" });
    //   return;
    // }

    const loanId = secondryCustData?.data?.id || secondryCustData?.id;

    const payload = {
      firstName: DOMPurify.sanitize(data.firstName),
      middleName: DOMPurify.sanitize(data.middleName) || "",
      lastName: DOMPurify.sanitize(data.lastName),
      mobileNumber: DOMPurify.sanitize(data.mobileNumber),
      dob: DOMPurify.sanitize(data.dob),
      address: DOMPurify.sanitize(data.address),
      email: DOMPurify.sanitize(data.email),
      country: DOMPurify.sanitize(data.country) || "Australia",
      city: DOMPurify.sanitize(data.city),
      state: DOMPurify.sanitize(data.state),
      zipcode: DOMPurify.sanitize(data.zip),
      marketPref: marketPreferences ? "Active" : "Inactive",
    };

    if (selectCustomer) {
      dispatch(addSecondryExistingData(loanId, selectCustomer?.id));
    } else {
      dispatch(addSecondryCustData(loanId, payload));
    }
    reset();
  };

  const handleEmail = (e) => {
    let emailAddress = e.target.value.trim();
    const isEmailExists = getAllCustomer?.data?.some(
      (customer) => customer.email.toLowerCase() === emailAddress.toLowerCase()
    );
    if (isEmailExists) {
      setError("email", { type: "manual", message: "Email already exists!" });
    } else {
      clearErrors("email");
    }
  };

  const handleCheckboxChange = () => {
    setAddNewCustomer(!addNewCustomer);
    setSearchTerm("");
  };

  const handleSelectCustomer = (customer) => {
    setSelectCustomer(customer);
  };

  useEffect(() => {
    if (!getAllCustomer?.data || !allLoanData?.data) return;
    const loanUserIds = new Set(
      allLoanData.data.flatMap(
        (cust) => cust?.loanRelations?.map((relation) => relation?.userId) || []
      )
    );
    const unmatchedCustomers = getAllCustomer.data.filter(
      (cust) => !loanUserIds.has(cust.id)
    );
    setFilteredData(unmatchedCustomers);
  }, [allLoanData, getAllCustomer]);

  const filteredCustomers = filteredData?.filter((cust) =>
    getName(cust).toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add Secondary Customer</Modal.Title>
      </Modal.Header>
      <div className="m-3">
        <input
          type="checkbox"
          id="addNewCustomer"
          className="form-check-input"
          checked={addNewCustomer}
          onChange={handleCheckboxChange}
        />
        <label className="form-check-label ms-2" htmlFor="addNewCustomer">
          Add New Secondary Customers/Guarantor
        </label>
      </div>

      <Modal.Body>
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          {!addNewCustomer ? (
            <>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Search a Secondary/Guaranteed Customers..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm && (
                <div className="card mt-4">
                  <div className="card-body">
                    <div className="customer-table">
                      <div className="table-responsive white-space-nowrap">
                        {filteredCustomers?.length > 0 ? (
                          <table className="table align-middle">
                            <thead className="table-light">
                              <tr>
                                <th>Select</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                              </tr>
                            </thead>
                            {console.log(
                              "filteredCustomers",
                              filteredCustomers
                            )}
                            <tbody>
                              {filteredCustomers?.map((customer, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="radio"
                                      name="selectedCustomer"
                                      value={customer.email}
                                      onChange={() =>
                                        handleSelectCustomer(customer)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <a
                                      className="d-flex align-items-center gap-3"
                                      href="#"
                                    >
                                      <p className="mb-0 customer-name fw-bold">
                                        {getName(customer)}
                                      </p>
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      href={`mailto:${customer.email}`}
                                      className="font-text1"
                                    >
                                      {customer.email}
                                    </a>
                                  </td>
                                  <td>{customer.mobile}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>No Data found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="col-md-4">
                <label htmlFor="firstName" className="form-label">
                  First Name<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="First Name"
                  className={`form-control ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                  {...register("firstName", {
                    required: MSG.FNAMEREQ,
                    pattern: {
                      value: NAME_REGEX,
                      message: "First name " + MSG.ALPHAREQ,
                    },
                    maxLength: {
                      value: 50,
                      message: MSG.MAX50CHREQ,
                    },
                  })}
                />
                <div className="invalid-feedback">
                  {errors.firstName?.message}
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="middleName" className="form-label">
                  Middle Name
                </label>
                <input
                  type="text"
                  id="middleName"
                  placeholder="Middle Name"
                  className="form-control"
                  {...register("middleName")}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="lastName" className="form-label">
                  Last Name<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  className={`form-control ${
                    errors.lastName ? "is-invalid" : ""
                  }`}
                  {...register("lastName", {
                    required: MSG.LNAMEREQ,
                    pattern: {
                      value: NAME_REGEX,
                      message: "Last name " + MSG.ALPHAREQ,
                    },
                    maxLength: {
                      value: 50,
                      message: MSG.MAX50CHREQ,
                    },
                  })}
                />
                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="mobileNumber" className="form-label">
                  Phone<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="mobileNumber"
                  placeholder="Phone"
                  className={`form-control ${
                    errors.mobileNumber ? "is-invalid" : ""
                  }`}
                  {...register("mobileNumber", {
                    required: MSG.MOBNUMREQ,
                    pattern: {
                      value: /^[0-9]+$/,
                      message: MSG.NUMREQ,
                    },
                  })}
                />
                <div className="invalid-feedback">
                  {errors.mobileNumber?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="email" className="form-label">
                  Email<span className="mandatoryField">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  {...register("email", {
                    onChange: (e) => handleEmail(e),
                    required: {
                      value: true,
                      message: MSG.EMAILSREQ,
                    },
                    pattern: {
                      value: EMAIL_REGEX,
                      message: MSG.INVEMAILREQ,
                    },
                    maxLength: {
                      value: 100,
                      message: MSG.MAX100CHREQ,
                    },
                  })}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </div>
              <div className="col-md-4">
                <label htmlFor="dob" className="form-label">
                  DOB<span className="mandatoryField">*</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  className={`form-control ${errors.dob ? "is-invalid" : ""}`}
                  {...register("dob", {
                    required: MSG.DOBREQ,
                  })}
                />
                <div className="invalid-feedback">{errors.dob?.message}</div>
              </div>

              <div className="col-md-12">
                <label htmlFor="address" className="form-label">
                  Street<span className="mandatoryField">*</span>
                </label>
                <textarea
                  id="address"
                  placeholder="Street"
                  rows="1"
                  className={`form-control ${
                    errors.address ? "is-invalid" : ""
                  }`}
                  {...register("address", {
                    required: MSG.STREETREQ,
                  })}
                ></textarea>
                <div className="invalid-feedback">
                  {errors.address?.message}
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="city" className="form-label">
                  Suburb / City<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="city"
                  placeholder="City"
                  className={`form-control ${errors.city ? "is-invalid" : ""}`}
                  {...register("city", {
                    required: MSG.CITYREQ,
                  })}
                />
                <div className="invalid-feedback">{errors.city?.message}</div>
              </div>

              <div className="col-md-4">
                <label htmlFor="state" className="form-label">
                  State<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="state"
                  placeholder="State"
                  className={`form-control ${errors.state ? "is-invalid" : ""}`}
                  {...register("state", {
                    required: MSG.STATEREQ,
                  })}
                />
                <div className="invalid-feedback">{errors.state?.message}</div>
              </div>

              <div className="col-md-4">
                <label htmlFor="zip" className="form-label">
                  Pin Code<span className="mandatoryField">*</span>
                </label>
                <input
                  type="text"
                  id="zip"
                  placeholder="Pin Code"
                  className={`form-control ${errors.zip ? "is-invalid" : ""}`}
                  {...register("zip", {
                    required: {
                      value: true,
                      message: MSG.ZCODE,
                    },
                    maxLength: 6,
                    pattern: {
                      value: NUM_REGEX,
                      message: MSG.INZCODE,
                    },
                  })}
                />
                <div className="invalid-feedback">{errors.zip?.message}</div>
              </div>

              <div className="col-md-6">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <select
                  id="country"
                  className="form-select"
                  {...register("country")}
                >
                  <option value="Australia">Australia</option>
                  <option value="USA">USA</option>
                  <option value="Canada">Canada</option>
                </select>
              </div>

              <div className="col-md-4">
                <label className="form-label">Market Preferences</label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="marketPreferencesToggle"
                    checked={marketPreferences}
                    onChange={toggleMarketPreferences}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="marketPreferencesToggle"
                  >
                    {marketPreferences ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>
            </>
          )}

          <hr />
          <div className="col-md-12 text-end">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddSecondaryCustomer;
