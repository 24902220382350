import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem('authToken');
  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default PublicRoute;
