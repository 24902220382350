import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addSecondryCustomer,
  openLoanDetailsPage,
  resetResponse,
} from "../../redux/actions";
import Home from "../../Home";
import { getName } from "../../utils/helper";
import { getAllCustomerData, getAllLoan } from "../../redux/actions/Api";
import Success from "../../Success";
import CustomerDetails from "../customer/CustomerDetails";
import LoanDetails from "./LoanDetails";

const LoanList = () => {
  const dispatch = useDispatch();
  const { allLoanData, response, getAllCustomer, openLoanDetails } =
    useSelector((data) => data?.collections);
  const [loanData, setLoanData] = useState([]);
  const [filteredLoanData, setFilteredLoanData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getAllLoan());
    dispatch(getAllCustomerData());
  }, []);

  useEffect(() => {
    if (allLoanData) {
      setLoanData(allLoanData);
      setFilteredLoanData(allLoanData?.data);
    }
  }, [allLoanData]);

  useEffect(() => {
    if (response) {
      dispatch(addSecondryCustomer(""));
      toast.info(<Success response={response} />, {});
      dispatch(resetResponse());
    }
  }, [response]);

  const handleAddSecondry = (value) => {
    dispatch(addSecondryCustomer(value));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredLoanData(loanData?.data);
    } else {
      const loanUser = allLoanData?.data?.flatMap(
        (cust) => cust?.loanRelations
      );
      const filtered = loanData?.data?.filter((loan) => {
        const bankMatch = loan?.bank
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());

        const relationMatch = loanUser?.filter((relation) => {
          console.log("relation:", relation?.mobile);

          const name = getName(relation);
          const email = relation?.email;
          const mobile = relation?.mobile ? relation.mobile.toString() : "";

          const nameMatch = name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
          const emailMatch = email
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
          const mobileMatch = mobile.includes(searchQuery);

          return nameMatch || emailMatch || mobileMatch;
        });

        return bankMatch || relationMatch;
      });

      setFilteredLoanData(filtered);
    }
    setCurrentPage(1);
  }, [searchQuery, loanData]);

  const totalItems = filteredLoanData?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = filteredLoanData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleLoanDetails = (val) => {
    dispatch(openLoanDetailsPage(val));
  };

  return (
    <Home>
      {openLoanDetails ? (
        <LoanDetails />
      ) : (
        <main class="main-wrapper">
          <div class="main-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div class="breadcrumb-title pe-3">Loans</div>
              <div class="ps-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0 p-0">
                    <li class="breadcrumb-item">
                      <a href="javascript:;">
                        <i class="bx bx-home-alt"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Loans List
                    </li>
                  </ol>
                </nav>
              </div>
              <div class="ms-auto">
                <div class="btn-group">
                  <button type="button" class="btn btn-primary">
                    Settings
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                    {" "}
                    <a class="dropdown-item" href="javascript:;">
                      All Customers
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Active Customers
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Inactive Customers
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="product-count d-flex align-items-center gap-3 gap-lg-4 mb-4 fw-bold flex-wrap font-text1">
              <a href="javascript:;">
                <span class="me-1">All</span>
                <span class="text-secondary">(85472)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">New</span>
                <span class="text-secondary">(145)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Checkouts</span>
                <span class="text-secondary">(89)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Locals</span>
                <span class="text-secondary">(5872)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Subscribers</span>
                <span class="text-secondary">(163)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Top Reviews</span>
                <span class="text-secondary">(8)</span>
              </a>
            </div>

            <div class="row g-3">
              <div class="col-auto">
                <div class="position-relative">
                  <input
                    className="form-control px-5"
                    type="search"
                    placeholder="Search Customers/Loans"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <span class="material-icons-outlined position-absolute ms-3 translate-middle-y start-0 top-50 fs-5">
                    search
                  </span>
                </div>
              </div>
              <div class="col-auto flex-grow-1 overflow-auto">
                <div class="btn-group position-static">
                  <div class="btn-group position-static">
                    <button
                      type="button"
                      class="btn btn-filter dropdown-toggle px-4"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More Filters
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="javascript:;">
                          All Loans
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="javascript:;">
                          Active Loans
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item" href="javascript:;">
                          Inactive Loans
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-auto">
                <div class="d-flex align-items-center gap-2 justify-content-lg-end">
                  <button class="btn btn-primary px-4">
                    <i class="bi bi-box-arrow-right me-2"></i>Export
                  </button>
                  {/* <button
                    class="btn btn-primary px-4"
                    onClick={handleAddContractOpen}
                  >
                    <i class="bi bi-plus-lg me-2"></i>Add Contract
                  </button> */}
                  {/* <button
                  class="btn btn-primary px-4"
                  onClick={handleAddLoanOpen}
                >
                  <i class="bi bi-plus-lg me-2"></i>Add Loans
                </button> */}
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-body">
                <div className="customer-table">
                  <div className="table-responsive white-space-nowrap">
                    <table className="table align-middle">
                      <thead className="table-light">
                        <tr>
                          <th>Customer Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Bank Name</th>
                          <th>Loan Amount</th>
                          <th>Loan Type</th>
                          <th>Settlement Date</th>
                          <th>Status</th>
                          <th>Secondry Customer</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((val, index) => (
                          <tr key={index}>
                            <td>
                              <a
                                className="d-flex align-items-center gap-3"
                                href="#"
                              >
                                <p
                                  className="mb-0 customer-name fw-bold"
                                  onClick={() => handleLoanDetails(val)}
                                >
                                  {getName(val?.loanRelations[0])}
                                </p>
                              </a>
                            </td>
                            <td>
                              <a
                                href={`mailto:${val?.loanRelations[0]?.email}`}
                                className="font-text1"
                              >
                                {val?.loanRelations[0]?.email}
                              </a>
                            </td>
                            <td>{val?.loanRelations[0]?.mobile}</td>
                            <td>{val?.bank}</td>
                            <td>{val?.loanAmount}</td>
                            <td>{val?.loanType}</td>
                            <td>{val?.settlementDate}</td>
                            <td>{"Active"}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-primary px-2 py-1"
                                onClick={() => handleAddSecondry(val)}
                              >
                                <i className="bi bi-plus"></i> Add Customer
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-3 p-3">
                  <div>
                    <label>
                      Items per page:{" "}
                      <select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </label>
                  </div>
                  <div>
                    <button
                      className={`btn btn-secondary me-2 nav-button ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className={`btn btn-secondary ms-2 nav-button next-button ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </Home>
  );
};

export default LoanList;
