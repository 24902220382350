import React, { useState } from "react";
import Home from "../../Home";


const Reports = () => {
  
  return (
    <Home>
      <main className="main-wrapper">
        <div className="main-content">
         <p>Reports</p>
        </div>
      </main>
    </Home>
  );
};

export default Reports;
