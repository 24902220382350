import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Home from "../../Home";
import { useDispatch, useSelector } from "react-redux";
import { selectedData } from "../../redux/actions";
import NewsletterUsers from "./NewsletterUsers";

const Newsletter = () => {
  const dispatch = useDispatch();
  const { allSelectedData, getAllCustomer } = useSelector(
    (data) => data?.collections
  );
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [model, setModel] = useState("");
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState([]);

  useEffect(() => {
    dispatch(selectedData());
  }, []);

  const handleEditorChange = (state) => {
    setEditorState(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    const textContent = state.getCurrentContent().getPlainText();
    setModel(textContent); 
  };

  const handleSendToCustomer = () => {
    setShowNewsletter(true);
  };

  useEffect(() => {
    if (getAllCustomer?.data && allSelectedData?.length > 0) {
      const selectedDetails = getAllCustomer.data.filter((val) =>
        allSelectedData.includes(val.cust_id)
      );
      setSelectedCustomerDetails(selectedDetails);
    }
  }, [getAllCustomer, allSelectedData]);

  const handleSave = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
  };

  return (
    <Home>
      <React.Fragment>
        <main className="main-wrapper">
          <div className="main-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div class="breadcrumb-title pe-3">Newsletter</div>
              <div class="ps-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0 p-0">
                    <li class="breadcrumb-item">
                      <a href="javascript:;">
                        <i class="bx bx-home-alt"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Mail
                    </li>
                  </ol>
                </nav>
              </div>
              <div class="ms-auto">
                <div class="btn-group">
                  <button type="button" class="btn btn-primary">
                    Settings
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                    {" "}
                    <a class="dropdown-item" href="javascript:;">
                      Action
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Separated link
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "colorPicker",
                  "link",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],
                inline: {
                  inDropdown: false,
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                blockType: {
                  inDropdown: true,
                  options: ["Normal", "H1", "H2", "H3", "Blockquote"],
                },
                fontSize: { inDropdown: true },
                fontFamily: { inDropdown: true },
                textAlign: {
                  inDropdown: false,
                  options: ["left", "center", "right", "justify"],
                },
                image: {
                  uploadEnabled: true,
                  urlEnabled: true,
                  previewImage: true,
                  alt: { present: true, mandatory: false },
                },
              }}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbarClassName="demo-toolbar"
            />
            {showNewsletter && model && <NewsletterUsers />}
            {allSelectedData?.length > 0 && model ? (
              <div className="col-md-12 text-end pt-3">
                {" "}
                <button onClick={handleSave} className="btn btn-primary">
                  Send
                </button>
              </div>
            ) : (
              <div className="col-md-12 text-end pt-3">
                {" "}
                <button
                  onClick={handleSendToCustomer}
                  className="btn btn-primary"
                  disabled={!model}
                >
                  Select Customers
                </button>
              </div>
            )}
          </div>
        </main>
      </React.Fragment>
    </Home>
  );
};

export default Newsletter;
