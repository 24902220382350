import React, { useState, useEffect } from "react";
import logo1 from "./assets/images/logoNew.png";
import login from "./assets/images/auth/login1.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

const VerifyOtp = () => {
  const dispatch = useDispatch();
  const { otpResponse } = useSelector((data) => data?.collections);
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", "blue-theme");
  }, []);

  useEffect(() => {
    if (otpResponse) {
      setMessage(otpResponse?.message);
      if (otpResponse?.success) {
        toast.success(otpResponse.message);

        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      } else {
        toast.error(otpResponse.message);
      }
    }
  }, [otpResponse]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      setMessage("Please enter the OTP");
      toast.error("Please enter the OTP");
      return;
    } 

    if (otp === otpResponse?.otp) {
      localStorage.setItem("auth", "true");
      toast.success("OTP Verified Successfully!");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 1000);
    } else {
      toast.error("Invalid OTP");
      setMessage("Invalid OTP");
    }
    setMessage();
  };

  return (
    <div className="section-authentication-cover">
      <div className="">
        <div className="row g-0">
          <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex border-end bg-transparent">
            <div className="card rounded-0 mb-0 border-0 shadow-none bg-transparent bg-none">
              <div className="card-body">
                <img
                  src={login}
                  className="img-fluid auth-img-cover-login"
                  width="650"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center border-top border-4 border-primary border-gradient-1">
            <div className="card rounded-0 m-3 mb-0 border-0 shadow-none bg-none">
              <div className="card-body p-sm-5">
                <img src={logo1} className="mb-4" width="70" alt="" />
                <span style={{ fontSize: "35px", fontWeight: "bold" }}>
                  MDS
                </span>
                <h4 className="fw-bold">Verify Your OTP</h4>
                <p className="mb-0">
                  An otp has been sent to ********k876@gmail.com
                </p>

                {message && (
                  <div className="alert alert-info mt-2" role="alert">
                    {message}
                  </div>
                )}

                <div className="form-body mt-5">
                  <form onSubmit={handleSubmit} className="row g-3">
                    <div className="col-12">
                      <label htmlFor="inputOtp" className="form-label">
                        Please enter OTP to verify
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputOtp"
                        placeholder="Enter Your OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <div className="d-grid">
                        <button type="submit" className="btn btn-grd-primary">
                          Verify
                        </button>
                      </div>
                    </div>

                    <div class="mt-3 text-center">
                      <a href="#">Resend OTP</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
