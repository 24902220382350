import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addContractOpen,
  addCustomersForm,
  addLoanForm,
  addSecondryCustomer,
  openCustomerDetails,
  resetResponse,
  resetResponseLoan,
} from "../../redux/actions";
import Home from "../../Home";
import { getName } from "../../utils/helper";
import CustomerDetails from "./CustomerDetails";
import { getAllCustomerData } from "../../redux/actions/Api";
import Success from "../../Success";

const CustomersList = () => {
  const dispatch = useDispatch();
  const { openCustomersPage, getAllCustomer, response,LoanData } = useSelector(
    (data) => data?.collections
  );
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [custData, setCustData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleAddCustomersOpen = () => {
    dispatch(addCustomersForm(true));
  };

  const handleAddContractOpen = () => {
    dispatch(addContractOpen(true));
  };

  useEffect(() => {
    if (response) {
      dispatch(addSecondryCustomer(""));
      toast.info(<Success response={response} />, {});
      dispatch(resetResponse());
    }else if(LoanData){
      dispatch(addSecondryCustomer(LoanData));
      toast.info(<Success response={LoanData} />, {});
      dispatch(addLoanForm(""));
      dispatch(resetResponseLoan())
    }
  }, [response,LoanData]);

  useEffect(() => {
    dispatch(addCustomersForm(false));
    dispatch(getAllCustomerData());
  }, []);

  useEffect(() => {
    if (getAllCustomer) {
      setCustomers(getAllCustomer);
      setFilteredCustomers(getAllCustomer?.data);
    }
  }, [getAllCustomer]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredCustomers(customers?.data || []);
    } else {
      const filtered = customers?.data?.filter((customer) => {
        const nameMatch = getName(customer)
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const emailMatch = customer?.cust_email
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        const mobileMatch = customer?.cust_mobileNumber
          ?.toString()
          .includes(searchQuery);
        const address = customer?.cust_address
          ?.toString()
          .includes(searchQuery);
        const city = customer?.city?.toString().includes(searchQuery);
        const state = customer?.state?.toString().includes(searchQuery);
        const zipCode = customer?.zipcode?.toString().includes(searchQuery);

        return (
          nameMatch ||
          emailMatch ||
          mobileMatch ||
          address ||
          city ||
          state ||
          zipCode
        );
      });
      setFilteredCustomers(filtered);
    }
    setCurrentPage(1);
  }, [searchQuery, customers]);

  const handleCustomerDetails = (val) => {
    dispatch(openCustomerDetails(val));
  };

  const totalItems = filteredCustomers?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = filteredCustomers?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleAddLoanOpen = (data) => {
    dispatch(addLoanForm(data));
  };

  return (
    <Home>
      {openCustomersPage ? (
        <CustomerDetails />
      ) : (
        <main class="main-wrapper">
          <div class="main-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div class="breadcrumb-title pe-3">Customers</div>
              <div class="ps-3">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0 p-0">
                    <li class="breadcrumb-item">
                      <a href="javascript:;">
                        <i class="bx bx-home-alt"></i>
                      </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Customers List
                    </li>
                  </ol>
                </nav>
              </div>
              <div class="ms-auto">
                <div class="btn-group">
                  <button type="button" class="btn btn-primary">
                    Settings
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                    {" "}
                    <a class="dropdown-item" href="javascript:;">
                      All Customers
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Active Customers
                    </a>
                    <a class="dropdown-item" href="javascript:;">
                      Inactive Customers
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="product-count d-flex align-items-center gap-3 gap-lg-4 mb-4 fw-bold flex-wrap font-text1">
              <a href="javascript:;">
                <span class="me-1">All</span>
                <span class="text-secondary">(85472)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">New</span>
                <span class="text-secondary">(145)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Checkouts</span>
                <span class="text-secondary">(89)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Locals</span>
                <span class="text-secondary">(5872)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Subscribers</span>
                <span class="text-secondary">(163)</span>
              </a>
              <a href="javascript:;">
                <span class="me-1">Top Reviews</span>
                <span class="text-secondary">(8)</span>
              </a>
            </div>

            <div class="row g-3">
              <div class="col-auto">
                <div class="position-relative">
                  <input
                    className="form-control px-5"
                    type="search"
                    placeholder="Search Customers"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <span class="material-icons-outlined position-absolute ms-3 translate-middle-y start-0 top-50 fs-5">
                    search
                  </span>
                </div>
              </div>
              <div class="col-auto flex-grow-1 overflow-auto">
                <div class="btn-group position-static">
                  <div class="btn-group position-static">
                    <button
                      type="button"
                      class="btn btn-filter dropdown-toggle px-4"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More Filters
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="javascript:;">
                          All Customers
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="javascript:;">
                          Active Customers
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item" href="javascript:;">
                          Inactive Customers
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-auto">
                <div class="d-flex align-items-center gap-2 justify-content-lg-end">
                  <button class="btn btn-filter px-4">
                    <i class="bi bi-box-arrow-right me-2"></i>Export
                  </button>
                  <button
                    class="btn btn-primary px-4"
                    onClick={handleAddContractOpen}
                  >
                    <i class="bi bi-plus-lg me-2"></i>Add Contract
                  </button>
                  <button
                    class="btn btn-primary px-4"
                    onClick={handleAddCustomersOpen}
                  >
                    <i class="bi bi-plus-lg me-2"></i>Add Customers
                  </button>
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-body">
                <div className="customer-table">
                  <div className="table-responsive white-space-nowrap">
                    <table className="table align-middle">
                      <thead className="table-light">
                        <tr>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          {/* <th>Date of Birth</th> */}
                          <th>Address</th>
                          <th>Status</th>
                          <th>Market Preferences</th>
                          <th>Add Loans</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((customer, index) => (
                          <tr key={index}>
                            <td>
                              <a
                                className="d-flex align-items-center gap-3"
                                href="#"
                              >
                                {/* <div className="customer-pic">
                              <img
                                src={avatars}
                                className="rounded-circle"
                                width="40"
                                height="40"
                                alt="Customer"
                              />
                            </div> */}
                                <p
                                  className="mb-0 customer-name fw-bold"
                                  onClick={()=>handleCustomerDetails(customer)}
                                >
                                  {getName(customer)}
                                </p>
                              </a>
                            </td>
                            <td>
                              <a
                                href={`mailto:${customer.email}`}
                                className="font-text1"
                              >
                                {customer.email}
                              </a>
                            </td>
                            <td>{customer.mobile}</td>
                            {/* <td>{customer.cust_dob}</td> */}
                            <td>
                              {customer.address} {customer.city}{" "}
                              {customer.state} {customer.country}{" "}
                              {customer.zipcode}
                            </td>
                            <td>{customer.status}</td>
                            <td>{customer?.customer?.marketPref}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-primary px-2 py-1"
                                onClick={()=>handleAddLoanOpen(customer)}
                              >
                                <i className="bi bi-plus"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3 p-3">
                <div>
                  <label>
                    Items per page:{" "}
                    <select
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                    </select>
                  </label>
                </div>
                <div>
                  <button
                    className={`btn btn-secondary me-2 nav-button ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className={`btn btn-secondary ms-2 nav-button next-button ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </Home>
  );
};

export default CustomersList;
