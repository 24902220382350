import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { addEmployeeForm } from "../../redux/actions";
import { MSG } from "../../utils/messages";
import { EMAIL_REGEX,NAME_REGEX, NUM_REGEX } from "../../utils/Regex";
import { addEmployeeData } from "../../redux/actions/Api";

const AddEmployee = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onTouched" });

  const [show, setShow] = useState(true);
  const [status, setStatus] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const roles = ["Sales Staff", "Loan Writer", "Manager", "Administrator"];

  const handleClose = () => {
    setShow(false);
    dispatch(addEmployeeForm(false));
  };

  const onSubmit = (data) => {
    const payload = {
      firstName: data.firstName,
      middleName: data.middleName || "",
      lastName: data.lastName,
      mobile: data.mobileNumber,
      DOB: data.dob,
      address: data.address,
      email: data.email,
      country: data.country || "Australia",
      city: data.city,
      state: data.state,
      zipcode: data.zip,
      status: status ? "Active" : "Inactive",
      jobTitle: selectedRole,
      hireDate: data.hiredate,
      salary: data.salary,
      Comp_ID: "2",
    };

    dispatch(addEmployeeData(payload));
    dispatch(addEmployeeForm(false));
    reset();
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-4">
            <label htmlFor="firstName" className="form-label">
              First Name<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              placeholder="First Name"
              className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
              {...register("firstName", {
                required: MSG.FNAMEREQ,
                pattern: {
                  value: NAME_REGEX,
                  message: "First name " + MSG.ALPHAREQ,
                },
                maxLength: {
                  value: 50,
                  message: MSG.MAX50CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="middleName" className="form-label">
              Middle Name
            </label>
            <input
              type="text"
              id="middleName"
              placeholder="Middle Name"
              className="form-control"
              {...register("middleName")}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="lastName" className="form-label">
              Last Name<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="lastName"
              placeholder="Last Name"
              className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
              {...register("lastName", {
                required: MSG.LNAMEREQ,
                pattern: {
                  value: NAME_REGEX,
                  message: "Last name " + MSG.ALPHAREQ,
                },
                maxLength: {
                  value: 50,
                  message: MSG.MAX50CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.lastName?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="mobileNumber" className="form-label">
              Phone<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="mobileNumber"
              placeholder="Phone"
              className={`form-control ${
                errors.mobileNumber ? "is-invalid" : ""
              }`}
              {...register("mobileNumber", {
                required: MSG.MOBNUMREQ,
                pattern: {
                  value: /^[0-9]+$/,
                  message: MSG.NUMREQ,
                },
              })}
            />
            <div className="invalid-feedback">
              {errors.mobileNumber?.message}
            </div>
          </div>

          <div className="col-md-4">
            <label htmlFor="dob" className="form-label">
              DOB<span className="mandatoryField">*</span>
            </label>
            <input
              type="date"
              id="dob"
              className={`form-control ${errors.dob ? "is-invalid" : ""}`}
              {...register("dob", {
                required: MSG.DOBREQ,
              })}
            />
            <div className="invalid-feedback">{errors.dob?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="email" className="form-label">
              Email<span className="mandatoryField">*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              {...register("email", {
                required: {
                  value: true,
                  message: MSG.EMAILSREQ,
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: MSG.INVEMAILREQ,
                },
                maxLength: {
                  value: 100,
                  message: MSG.MAX100CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="salary" className="form-label">
              Salary<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="salary"
              placeholder="Salary"
              className={`form-control ${errors.salary ? "is-invalid" : ""}`}
              {...register("salary", {
                required: MSG.SALARYREQ,
                pattern: {
                  value: /^\d+(\.\d{1,2})?$/,
                  message: MSG.NUMREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.salary?.message}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="jobtitle" className="form-label">
              Job Title
            </label>
            <select
              id="jobtitle"
              className={`form-control ${!selectedRole && errors.jobtitle ? "is-invalid" : ""}`}
              {...register("jobtitle", {
                required: MSG.JOBREQ,
              })}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <option value="">Select a role...</option>
              {roles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">{!selectedRole && errors.jobtitle?.message}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="hiredate" className="form-label">
              Hire Date<span className="mandatoryField">*</span>
            </label>
            <input
              type="date"
              id="hiredate"
              className={`form-control ${errors.hiredate ? "is-invalid" : ""}`}
              {...register("hiredate", {
                required: MSG.HIREREQ,
              })}
            />
            <div className="invalid-feedback">{errors.hiredate?.message}</div>
          </div>
         
          <div className="col-md-6">
            <label htmlFor="address" className="form-label">
              Street<span className="mandatoryField">*</span>
            </label>
            <textarea
              id="address"
              placeholder="Street"
              rows="1"
              className={`form-control ${errors.address ? "is-invalid" : ""}`}
              {...register("address", {
                required: MSG.STREETREQ,
              })}
            ></textarea>
            <div className="invalid-feedback">{errors.address?.message}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <select
              id="country"
              className="form-select"
              {...register("country")}
            >
              <option value="Australia">Australia</option>
              {/* <option value="USA">USA</option>
            <option value="Canada">Canada</option> */}
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="city" className="form-label">
              Suburb / City<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="city"
              placeholder="City"
              className={`form-control ${errors.city ? "is-invalid" : ""}`}
              {...register("city", {
                required: MSG.CITYREQ,
              })}
            />
            <div className="invalid-feedback">{errors.city?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="state" className="form-label">
              State<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="state"
              placeholder="State"
              className={`form-control ${errors.state ? "is-invalid" : ""}`}
              {...register("state", {
                required: MSG.STATEREQ,
              })}
            />
            <div className="invalid-feedback">{errors.state?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="zip" className="form-label">
              Pin Code<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="zip"
              placeholder="Pin Code"
              className={`form-control ${errors.zip ? "is-invalid" : ""}`}
              {...register("zip", {
                required: {
                  value: true,
                  message: MSG.ZCODE,
                },
                maxLength: 6,
                pattern: {
                  value: NUM_REGEX,
                  message: MSG.INZCODE,
                },
              })}
            />
            <div className="invalid-feedback">{errors.zip?.message}</div>
          </div>

          {/* <div className="col-md-4">
            <label className="form-label">Status</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={status}
                onChange={() => setStatus(!status)}
              />
              <label className="form-check-label">
                {status ? "Active" : "Inactive"}
              </label>
            </div>
          </div> */}
          <hr />
          <div className="col-md-12 text-end">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEmployee;
