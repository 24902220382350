import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
export { PrivateRoute, PublicRoute };

// let API_BASE_PATH = "http://127.0.0.1:8000/api/v1"
let API_BASE_PATH = "https://webhosting-mydigitalsolutions.com.au/api/v1";

export const TOAST_TIMER = 5000;

export const PATHS = {
  USERSIGNIN: "/signin",
  USERSIGNUP: "/signup",
  HOME: "/home",
  DASHBOARD: "/dashboard",
  COMPANY: "/company",
  EMPLOYEE: "/employee",
  CUSTOMER: "/customers",
  LOANDETAILS: "/loan-details",
  HELP: "/help",
  PDFUPLOAD: "/pdfupload",
  MARKETING: "/marketing/newsletter",
  SMS: "/marketing/sms",
  BIRTHDAY: "/marketing/birthdays",
  REPORTS: "/reports",
  ERROR: "/error404",
  VERIFYOTP: "/verifyotp",
};

export const API_PATHS = {
  FREE_SIGNIN_API: `${API_BASE_PATH}/login`,
  GET_ALL_CUSTOMERS: `${API_BASE_PATH}/customers`,
  ADD_CUSTOMERS: `${API_BASE_PATH}/users`,
  ADD_SECONDRY_CUSTOMERS: `${API_BASE_PATH}/loans`,
  ADD_EMPLOYEE: `${API_BASE_PATH}/employees`,
  GET_ALL_EMPLOYEE: `${API_BASE_PATH}/employees`,
  SUBSCRIBER_API: `${API_BASE_PATH}`,
  GET_ALL_COMPANY: `${API_BASE_PATH}/companies`,
  GET_ALL_LOAN: `${API_BASE_PATH}/loan-details`,
  GET_LOAN_PRODUCTS: `${API_BASE_PATH}/loan-products`,
  POST_LOAN_DETAILS: `${API_BASE_PATH}/loan-details`,
  UPDATE_THEME: `${API_BASE_PATH}/update-theme`,
};
