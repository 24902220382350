import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import { MSG } from "../../utils/messages";
import { EMAIL_REGEX, NAME_REGEX, NUM_REGEX } from "../../utils/Regex";
import { addCompanyForm } from "../../redux/actions";
import { addCompanyData } from "../../redux/actions/Api";

const AddCompany = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onTouched" });

  const [show, setShow] = useState(true);
  const [status, setStatus] = useState(false);

  const handleClose = () => {
    dispatch(addCompanyForm(false));
    setShow(false);
  };

  const toggleStatus = () => {
    setStatus(!status);
  };

  const onSubmit = (data) => {
    const payload = {
      firstName: DOMPurify.sanitize(data.fullName),
      companyName: DOMPurify.sanitize(data.companyName),
      email: DOMPurify.sanitize(data.email),
      mobile: DOMPurify.sanitize(data.mobileNumber),
      password: DOMPurify.sanitize(data.password),
      abnNumber: DOMPurify.sanitize(data.abnNumber),
      acnNumber: DOMPurify.sanitize(data.acnNumber),
      streetName: DOMPurify.sanitize(data.streetName),
      city: DOMPurify.sanitize(data.city),
      state: DOMPurify.sanitize(data.state),
      zipcode: DOMPurify.sanitize(data.zip),
      country: DOMPurify.sanitize(data.country || "Australia").toUpperCase(),
      customerLimit: DOMPurify.sanitize(data.customerLimit),
      employeeLimit: DOMPurify.sanitize(data.employeeLimit)
    };
    dispatch(addCompanyData(payload));
    dispatch(addCompanyForm(false));
    reset();
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <h6>Company Details </h6>
          <div className="col-md-6">
            <label htmlFor="companyName" className="form-label">
            Company Name<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="companyName"
              placeholder="Company Name"
              className={`form-control ${errors.companyName ? "is-invalid" : ""}`}
              {...register("companyName", {
                required: MSG.NAMEREQ,
                pattern: {
                  value: NAME_REGEX,
                  message: "First name " + MSG.ALPHAREQ,
                },
                maxLength: {
                  value: 50,
                  message: MSG.MAX50CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.companyName?.message}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              Email<span className="mandatoryField">*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              {...register("email", {
                required: {
                  value: true,
                  message: MSG.EMAILSREQ,
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: MSG.INVEMAILREQ,
                },
                maxLength: {
                  value: 100,
                  message: MSG.MAX100CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="mobileNumber" className="form-label">
              Phone<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="mobileNumber"
              placeholder="Phone"
              className={`form-control ${
                errors.mobileNumber ? "is-invalid" : ""
              }`}
              {...register("mobileNumber", {
                required: MSG.PHONENUMREQ,
                pattern: {
                  value: /^[0-9]+$/,
                  message: MSG.NUMREQ,
                },
              })}
            />
            <div className="invalid-feedback">
              {errors.mobileNumber?.message}
            </div>
          </div>
          
          <div className="col-md-4">
            <label htmlFor="abnNumber" className="form-label">
              ABN Number<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="abnNumber"
              placeholder="ABN Number"
              className={`form-control ${errors.abnNumber ? "is-invalid" : ""}`}
              {...register("abnNumber", {
                required: MSG.ABNNUMREQ,
                pattern: {
                  value: /^[a-zA-Z0-9]{11}$/,
                  message: MSG.VALID_ABN,
                },
              })}
            />
            <div className="invalid-feedback">{errors.abnNumber?.message}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="acnNumber" className="form-label">
              ACN Number<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="acnNumber"
              placeholder="ACN Number"
              className={`form-control ${errors.acnNumber ? "is-invalid" : ""}`}
              {...register("acnNumber", {
                required: MSG.ACNNUMREQ,
                pattern: {
                  value: /^[a-zA-Z0-9]{9}$/,
                  message: MSG.VALID_ABN,
                },
              })}
            />
            <div className="invalid-feedback">{errors.acnNumber?.message}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="streetName" className="form-label">
              Street<span className="mandatoryField">*</span>
            </label>
            <textarea
              id="streetName"
              placeholder="Street"
              rows="1"
              className={`form-control ${errors.streetName ? "is-invalid" : ""}`}
              {...register("streetName", {
                required: MSG.STREETREQ,
              })}
            ></textarea>
            <div className="invalid-feedback">{errors.streetName?.message}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <select
              id="country"
              className="form-select"
              {...register("country")}
            >
              <option value="Australia">Australia</option>
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="city" className="form-label">
              Suburb / City<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="city"
              placeholder="City"
              className={`form-control ${errors.city ? "is-invalid" : ""}`}
              {...register("city", {
                required: MSG.CITYREQ,
              })}
            />
            <div className="invalid-feedback">{errors.city?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="state" className="form-label">
              State<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="state"
              placeholder="State"
              className={`form-control ${errors.state ? "is-invalid" : ""}`}
              {...register("state", {
                required: MSG.STATEREQ,
              })}
            />
            <div className="invalid-feedback">{errors.state?.message}</div>
          </div>

          <div className="col-md-4">
            <label htmlFor="zip" className="form-label">
              Pin Code<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="zip"
              placeholder="Pin Code"
              className={`form-control ${errors.zip ? "is-invalid" : ""}`}
              {...register("zip", {
                required: {
                  value: true,
                  message: MSG.ZCODE,
                },
                maxLength: 6,
                pattern: {
                  value: NUM_REGEX,
                  message: MSG.INZCODE,
                },
              })}
            />
            <div className="invalid-feedback">{errors.zip?.message}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="employeeLimit" className="form-label">
            Employes Limit<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="employeeLimit"
              placeholder="Employes Limit"
              className={`form-control ${errors.employeeLimit ? "is-invalid" : ""}`}
              {...register("employeeLimit", {
                required: {
                  value: true,
                  message: MSG.ZCODE,
                },
                maxLength: 6,
                pattern: {
                  value: NUM_REGEX,
                  message: MSG.INZCODE,
                },
              })}
            />
            <div className="invalid-feedback">{errors.employeeLimit?.message}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="customerLimit" className="form-label">
            Customers Limit<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="customerLimit"
              placeholder=" Customers Limit"
              className={`form-control ${errors.customerLimit ? "is-invalid" : ""}`}
              {...register("customerLimit", {
                required: {
                  value: true,
                  message: MSG.ZCODE,
                },
                maxLength: 6,
                pattern: {
                  value: NUM_REGEX,
                  message: MSG.INZCODE,
                },
              })}
            />
            <div className="invalid-feedback">{errors.customerLimit?.message}</div>
          </div>
         <hr/>
         <h6 className="mt-0">Admin Details </h6>
         <div className="col-md-6">
            <label htmlFor="fullName" className="form-label">
              Full Name<span className="mandatoryField">*</span>
            </label>
            <input
              type="text"
              id="fullName"
              placeholder="Full Name"
              className={`form-control ${errors.fullName ? "is-invalid" : ""}`}
              {...register("fullName", {
                required: MSG.FULLNAMEREQ,
                pattern: {
                  value: NAME_REGEX,
                  message: "Full name " + MSG.ALPHAREQ,
                },
                maxLength: {
                  value: 50,
                  message: MSG.MAX50CHREQ,
                },
              })}
            />
            <div className="invalid-feedback">{errors.fullName?.message}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="password" className="form-label">
              Password<span className="mandatoryField">*</span>
            </label>
            <input
              type="password"
              id="password"
              placeholder="password"
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              {...register("password", {
                required: MSG.PASSREQ,
                minLength: { value: 8, message: MSG.EIGHTCHAR },
              })}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
          <div className="col-md-12 text-end">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCompany;
