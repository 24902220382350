import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Home from "../../Home";
import { useDispatch, useSelector } from "react-redux";
import { selectedData } from "../../redux/actions";
import BirthdayUsers from "./BirthdayUsers";

const BirthdayEditor = () => {
  const dispatch = useDispatch();
  const { allSelectedData, getAllCustomer } = useSelector(
    (data) => data?.collections
  );

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState("");
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState([]);

  useEffect(() => {
    dispatch(selectedData());
  }, []);

  useEffect(() => {
    if (getAllCustomer?.data && allSelectedData?.length > 0) {
      const selectedDetails = getAllCustomer?.data.filter((val) =>
        allSelectedData.includes(val?.cust_id)
      );
      setSelectedCustomerDetails(selectedDetails);
    }
  }, [getAllCustomer, allSelectedData]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    const textContent = state.getCurrentContent().getPlainText();
    setContent(textContent); 
  };


  const handleSubmitData = (e) => {
    e.preventDefault();

  };

  return (
    <Home>
      <main className="main-wrapper">
        <div className="main-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Birthday</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="javascript:;">
                      <i className="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Messages
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          {<BirthdayUsers />}

          <form onSubmit={handleSubmitData}>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  "colorPicker",
                  "link",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                blockType: {
                  options: ["Normal", "H1", "H2", "H3", "Blockquote"],
                },
                textAlign: {
                  options: ["left", "center", "right", "justify"],
                },
                image: {
                  uploadEnabled: true,
                  urlEnabled: true,
                  previewImage: true,
                  alt: { present: true, mandatory: false },
                },
              }}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbarClassName="demo-toolbar"
            />

            {allSelectedData?.length > 0 && content ? (
              <div className="col-md-12 text-end pt-3">
                <button type="submit" className="btn btn-primary">
                  Send
                </button>
              </div>
            ) : (
              <div className="col-md-12 text-end pt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!content}
                >
                  Select Customers
                </button>
              </div>
            )}
          </form>
        </div>
      </main>
    </Home>
  );
};

export default BirthdayEditor;
